<div *ngIf="!isTLsEmpty; else noTeachingLanguages" class="table-wrapper">

    <table class="table table-hover">

        <thead>
            <th scope="col">{{ 'label-language' | transloco }}</th>
            <th scope="col">{{ 'label-language-level' | transloco }}</th>
            <th scope="col">{{ 'label-is-individual' | transloco }}</th>
            <th scope="col">{{ 'label-price' | transloco }}</th>
        </thead>

        <tbody>
            <ng-container *ngFor="let tl of teachingLanguages">

                <ng-container *ngIf="tl.teachingLanguageItems.length > 0">

                    <tr *ngFor="let tlItem of tl.teachingLanguageItems;let i = index">
                        <th scope="row">{{ i> 0 ? '' : tl.languageValue}}</th>
                        <td>{{tlItem.languageLevelValue}}</td>
                        <td>{{tlItem.isIndividual | yesNo}}</td>
                        <td>{{tlItem.price | currencyFormat}}</td>
                    </tr>

                </ng-container>

            </ng-container>

        </tbody>

    </table>

</div>

<ng-template #noTeachingLanguages>
    <h4>{{ 'priciing-table-no-data' | transloco }}</h4>
</ng-template>
