<input type="file" (change)="onImageUploadChange($event)" accept="image/*">


<div class="cropper-wrapper">

    <div *ngIf="croppedImage">
        <button (click)="zoomOut()">Zoom -</button>
        <button (click)="zoomIn()">Zoom +</button>
    </div>

    <image-cropper
    [imageChangedEvent]="imageChagnedEvent"
    [maintainAspectRatio]="true"
    [resizeToWidth]="600"
    [resizeToWidth]="300"
    [resizeToHeight]="300"
    [cropperMinHeight]="300"
    [cropperMinWidth]="300"
    [onlyScaleDown]="false"
    [backgroundColor]="'transparent'"
    [roundCropper]="true"
    [transform]="imageTransform"
    format="png"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
    ></image-cropper>

    <div class="form-wrappe my-3">

        <div *ngIf="croppedImage" class="profile-picture-wrapper mb-3">
    
            <img [src]="croppedImage">
    
        </div>
    
    </div>

    
    <form [formGroup]="professorPictureForm" (ngSubmit)="uploadProfessorPicture()">
        <button type="submit" class="btn btn-primary btn-sm mt-3" [disabled]="professorPictureForm.invalid || isUploading">{{ 'btn-upload-image' | transloco }}</button>
    </form>
    
</div>


