<footer class="footer footer-bg bg-dark pt-5 pt-md-6">
    <div class="container pt-3 pt-md-0">
        <div class="row mb-4">
            <div class="col-md-6 mb-3">
                <div class="d-sm-flex justify-content-between">
                    <div class="widget widget-light pb-1 mb-4">
                        <h4 class="widget-title">{{ 'footer-general' | transloco }}</h4>
                        <ul>
                            <li><a class="widget-link cursor-p" (click)="navigateTo('faq')">{{ 'footer-faq' | transloco }}</a></li>
                            <li><a class="widget-link cursor-p" (click)="navigateTo('services')">{{ 'footer-services' | transloco }}</a></li>
                            <li><a class="widget-link cursor-p" (click)="navigateTo('terms')">{{ 'footer-terms' | transloco }}</a></li>
                            <li><a class="widget-link cursor-p" (click)="navigateTo('privacy-policy')">{{ 'footer-privacy-policy' | transloco }}</a></li>
                            <li><a class="widget-link cursor-p"  (click)="navigateTo('about')">{{ 'footer-about' | transloco }}</a></li>
                            <li><a class="widget-link cursor-p"  (click)="navigateTo('contact')">{{ 'footer-contact-open' | transloco }}</a></li>
                        </ul>
                    </div>
                    <div class="widget widget-light pb-1 mb-4">
                        <h4 class="widget-title">{{ 'footer-support' | transloco }}</h4>
                        <ul>
                            <li><a class="widget-link cursor-p"  (click)="navigateTo('registration')">{{ 'footer-registration' | transloco }}</a></li>
                            <li><a class="widget-link cursor-p"  (click)="navigateTo('student')">{{ 'footer-student' | transloco }}</a></li>
                            <li><a class="widget-link cursor-p"  (click)="navigateTo('membership')">{{ 'footer-pro-memb' | transloco }}</a></li>
                            <li><a class="widget-link cursor-p"  (click)="navigateTo('basic')">{{ 'footer-basic-memb' | transloco }}</a></li>
                            <li><a class="widget-link cursor-p"  (click)="navigateTo('tips')">{{ 'footer-tips' | transloco }}</a></li>
                            <li *ngIf="accountService.currentUser$ | async"><a class="widget-link cursor-p" (click)="openContactModal(contactModal)">{{ 'footer-contact' | transloco }}</a></li>
                        </ul>
                    </div>
                    <div class="widget widget-light pb-1 mb-4">
                        <h4 class="widget-title">Social Media</h4>
                        <ul>
                          <li><a class="widget-link cursor-p" target="_blank" href="https://www.tiktok.com/@mylanguage.guru">TikTok</a></li>
                          <li><a class="widget-link cursor-p" target="_blank" href="https://www.instagram.com/my_language_guru/">Instagram</a></li>
                          <li><a class="widget-link cursor-p" target="_blank" href="https://www.youtube.com/@mylanguageguru">YouTube</a></li>
                          <li><a class="widget-link cursor-p" target="_blank" href="https://www.facebook.com/profile.php?id=100092412745760">Facebook</a></li>
                          <li><a class="widget-link cursor-p" target="_blank" href="https://twitter.com/mylanguageguru">Twitter</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-darker py-4 py-md-3">
        <div class="container d-md-flex align-items-center justify-content-center pt-3">
            <div class="d-flex flex-wrap align-items-center order-md-1">
                <a class="d-block me-grid-gutter mb-3" href="index.html" style="width: 200px;">
                    <img width="200" src="../../../assets/svgs/mlg-logo.svg" alt="MLG">
                </a>
                <div class="d-flex flex-column">
                  <p class="fs-sm pt-2 mb-3">
                    <span class="text-light opacity-50 me-1">© All rights reserved. Made by</span>
                    <a class="nav-link-style nav-link-light" href="#"
                        target="_blank" rel="noopener">GURUPOINT LLC</a>
                  </p>
                  <p>
                    <span>8 THE GREEN, STE R, Dover, 19901 Delaware
                      contact@gurupoint.net
                      +13024870981</span>
                  </p>
                </div>
                
            </div>
        </div>
    </div>
</footer>


<ng-template #contactModal let-modal>

  <div class="container">

    <div class="mx-3 my-3" *ngIf="!isContactFormEnabled">
      <p>{{ 'section-contact-p1' | transloco }}</p>

      <div class="contact-buttons-allow">
        <button class="btn-success btn-sm" (click)="enableContactForm()">OK</button>
        <button class="btn-danger btn-sm" (click)="closeContactForm()">Close</button>
      </div>

    </div>





    <form *ngIf="isContactFormEnabled" [formGroup]="contactForm" (ngSubmit)="onSubmitContactForm()">

      <h2>{{ 'section-contact-h' | transloco }}</h2>

      <p>{{ 'section-contact-p2' | transloco }}</p>

      <div class="form-group mb-3">
        <div class="form-floating has-icon">
            <i class="ai-user"></i>
            <input readonly formControlName="name" name="name" type="text" class="form-control" id="name">
            <label for="name">{{ 'section-contact-name' | transloco }}</label>
        </div>
      </div>

      <div class="form-group mb-3">
        <div class="form-floating has-icon">
            <i class="ai-mail"></i>
            <input readonly formControlName="email" name="email" type="email" id="email" class="form-control" placeholder="johndoe@example.com">
            <label for="email">{{ 'section-contact-email' | transloco }}<span class="mandatory"> *</span></label>
        </div>
      </div>

      <div class="form-group">
        <div class="form-floating has-icon">
          <i class="ai-arrow-right"></i>
          <select formControlName="category" name="category" id="category" class="form-select text-center">

            <option *ngFor="let option of contactOptions" [value]="option.id">{{ option.name }}</option>

          </select>
          <label for="category">{{ 'section-contact-categories' | transloco }}</label>
        </div>
      </div>

      <div class=" mt-3 form-group mb-3">
        <div class="form-floating has-icon">
            <i class="ai-edit-2"></i>
            <label for="message-text">{{ 'section-contact-message' | transloco }}<span class="mandatory"> *</span></label>
            <textarea formControlName="message" name="message-text" class="form-control px-5 py-5 h-auto" rows="10" id="message-text"></textarea>
        </div>

        <div *ngIf="contactForm.controls.message.invalid"
        class="form-invalid-input-alert">
          <small *ngIf="contactForm.controls.message.errors?.required">
            {{ 'contact-validation-message-required' | transloco }}
          </small>
        </div>
        
      </div>

      <div class="my-3 contact-buttons-allow">
        <button type="submit" [disabled]="contactForm.invalid" class="btn-success btn-sm">{{ 'btn-send-contact' | transloco }}</button>
        <button class="btn-danger btn-sm" (click)="closeContactForm()">Close</button>
      </div>

    </form>

  </div>

</ng-template>
