import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { Subject } from 'rxjs';
import { LoginComponent } from 'src/app/content/pages/identity/login/login.component';
import { AccountService } from 'src/app/core/services/account.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  @Input() navColor: string = "navbar-light";
  @Input() showRegisterButton: boolean = true;

  @ViewChild(LoginComponent) loginRef :LoginComponent;

  // logoUrl: string = "../../../assets/svgs/logo_lottie_black.json";

  openStudentRegisterModalSubject: Subject<void> = new Subject<void>();
  openLoginModalSubject: Subject<void> = new Subject<void>();
  openProfessorRegisterModalSubject: Subject<void> = new Subject<void>();

  lottiePlayerOptions: any = {
    path: ''
  };

  constructor(
    public accountService: AccountService
  ) { }

  ngOnInit(): void {

    let logoUrl = "";

    if (this.navColor === "navbar-light") {

      logoUrl = "../../../assets/svgs/mlg-logo-lottie.json"
    }

    else {

      logoUrl = "../../../assets/svgs/mlg-logo-lottie.json";

    }

    this.lottiePlayerOptions.path = logoUrl;

  }

  onOpenStudentRegisterModal() {

    this.openStudentRegisterModalSubject.next();

  }

  onOpenProfessorRegisterModal() {

    this.openProfessorRegisterModalSubject.next();
    
  }

  onOpenLoginModal() {

    this.openLoginModalSubject.next();
    
  }

  onOpenLoginModalEvent(event:any) {

    this.loginRef.setParamsAfterRegistration(event.parameters);
    this.openLoginModalSubject.next();
    
  }



}
