import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private openLoginModalSubscription!: Subscription;
  @Input() openLoginModalEvent!: Observable<void>;

  @ViewChild("loginModal") loginModal!: NgbModalRef;
  loginModalReference!: any;

  @ViewChild("forgotPasswordModal") forgotPasswordModal!:NgbModalRef;
  forgotPasswordModalReference!: any;

  @ViewChild("newPasswordModal") newPasswordModal!:NgbModalRef;
  newPasswordModalReference!: any;

  @Output() openStudentRegisterModalFromLogin = new EventEmitter<Event>();

  constructor(
    private _modalService: NgbModal,
    private _accountService: AccountService,
    private _router: Router,
    private _toastr: ToastrService,
    public translocoService: TranslocoService
    ) { }

  isLoginSpinnerVisible: boolean = false;
  isInvalidEmailPassword: boolean = false;
  isLoginButtonDisabled: boolean = false;

  ngOnInit(): void {

    this.openLoginModalSubscription = this.openLoginModalEvent.subscribe(() => {

      this.openModal(this.loginModal);

    });

  }

  ngOnDestroy() {
    this.openLoginModalSubscription.unsubscribe();
  }

  openModal(content: any) {

    this.loginModalReference = this._modalService.open(

      content,

      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        modalDialogClass: "login-modal",
        backdropClass: "login-modal-backdrop"
      });

    this.loginModalReference.result.then((result: any) => {

    }, (reason: any) => {

      console.log("Login modal closed");

    });

  }

  passwordMatchingValidatior: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');

    return password?.value === confirmPassword?.value ? null : { notmatched: true };
  };

  loginForm: UntypedFormGroup = new UntypedFormGroup({

    email: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required])

  });

  forgotPasswordForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('',[Validators.required, Validators.email])
  });

  newPasswordForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    verificationCode: new UntypedFormControl('',[Validators.required,]),
    password: new UntypedFormControl('', [Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$^+=!*()@%&]).{8,}$")]),
    confirmPassword: new UntypedFormControl('', [Validators.required])
  }, { validators: this.passwordMatchingValidatior })



  onClickLogin() {

    let formValues = this.loginForm.value;

    this.isLoginButtonDisabled = true;
    this.isLoginSpinnerVisible = true;

    this._accountService.login(formValues).subscribe(result => {

      this.isLoginSpinnerVisible = false;
      this.isLoginButtonDisabled = false;

      if (result === true) {

        this.loginModalReference.close();

        if(this._accountService.getUserStageLocalStorage() !== "Completed") {
          this._router.navigate(['/account']);
        } else {
          this._router.navigate(['/']);
        }


      } else {

        this.isInvalidEmailPassword = true;

      }
    });
  }

  onClickForgotPassword() {
    let formValues = this.forgotPasswordForm.value;

    this.isLoginSpinnerVisible = true;

    this._accountService.sendResetPasswordToken(formValues).pipe(finalize(() => this.isLoginSpinnerVisible = false))
    .subscribe(
      result => {
        this._toastr.success(this.translocoService.translate('msg-reset-password-sent-email'));
        this.forgotPasswordModalReference.close();


        this.newPasswordForm.patchValue({
          email: this.forgotPasswordForm.get('email')?.value
        })
        this.newPasswordModalReference = this._modalService.open(
          this.newPasswordModal,
          {
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
            modalDialogClass: "login-modal",
            backdropClass: "login-modal-backdrop"
          }
        );
        this.newPasswordModalReference.result.then(
          (result: any) => {},
          (reason: any) => {

            console.log("Login modal closed");

          }
        );
      }
    )
  }

  onClickSaveNewPassword() {
    const formData = this.newPasswordForm.value;

    const payload  = {
      email: formData.email,
      verificationCode: formData.verificationCode,
      newPassword: formData.password
    }

    this._accountService.resetUserPassword(payload).subscribe(
      (response) => {
        this._toastr.success(this.translocoService.translate('msg-reset-password-changed'));
        this.newPasswordModalReference.close();
      },
      (error) => {
        if(error.detail === "Bad email or verification code") {
          this._toastr.error(this.translocoService.translate('msg-wrong-code'));
        } else {
          this._toastr.error(this.translocoService.translate('msg-something-went-wrong'))
        }
      }
    )
  }

  onClickOpenStudentRegisterModal() {

    this.loginModalReference.close();

    this.openStudentRegisterModalFromLogin.emit();

  }

  onForgotPassword() {
    this.loginModalReference.close();

    this.forgotPasswordModalReference = this._modalService.open(

      this.forgotPasswordModal,

      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        modalDialogClass: "login-modal",
        backdropClass: "login-modal-backdrop"
      });

    this.forgotPasswordModalReference.result.then((result: any) => {

    }, (reason: any) => {

      console.log("Login modal closed");

    });

  }

  setParamsAfterRegistration(params: { email: string, password: string}) {
    this.loginForm.controls['email'].setValue(params.email);
    this.loginForm.controls['password'].setValue(params.password);
  }
}
