import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./content/pages/pages.module').then(m => m.PagesModule),
    data: {
      title: 'Language Learning Solutions - My Language Guru',
      descrption: 'Discover Language Learning Solutions at My Language Guru. Access our resources, courses, and services to enhance your language skills effectively.',
      ogTitle: 'Language Learning Solutions - My Language Guru',
    }
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
  static components = [AppComponent]
}
