import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfessorMember } from 'src/app/core/models/member';
import { AccountService } from 'src/app/core/services/account.service';
import { DataStorageService } from 'src/app/core/services/data-storage/data-storage.service';

@Component({
  selector: 'app-professor-compact-card',
  templateUrl: './professor-compact-card.component.html',
  styleUrls: ['./professor-compact-card.component.scss']
})
export class ProfessorCompactCardComponent implements OnInit {

  profilePictureUrl: string = "/assets/img/dashboard/avatar/main-sm.jpg"
  professorName: string;
  professorCurrentRating: number = 0;
  professorTeachingLanguages: any[] = [];
  professorCoachingLanguages: any[] = [];
  isPro: boolean;

  maxRating: number = 5.00;

  @Input() professor: ProfessorMember;

  constructor(
    public accountService: AccountService,
    public dataStorageService: DataStorageService,
    private _router: Router
  ) { }

  ngOnInit(): void { 
    this.professorName = this.professor.firstName + " " + this.professor.lastName;
    this.profilePictureUrl = this.professor.imageUrl;
    this.professorCurrentRating = this.professor.averageRating;
    this.professorTeachingLanguages = this.professor.teachingLanguages;
    this.professorCoachingLanguages = this.professor.coachingLanguages;
    this.isPro = this.professor.isPro;
  }

  checkIfTeacherIsFavourite(list: Array<any>) {
    return list.some(teacher => teacher.professorId === this.professor.professorId);
  }

  afterChatCreated(event: any, professorFirstName: any, professorLastName: any, professorId: any, studentId: any) {
    if(event == 404) {
      this._router.navigate(['messages',
       { 
        studentId: studentId,
        studentName: professorFirstName,
        studentSurname: professorLastName,
        professorId: professorId
      }])
    } else {
      const conversationId = event.conversationDto.conversationId;
      this._router.navigate(['messages', { id: conversationId }])
    }
  }
}
