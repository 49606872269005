<div class="container p-3 d-flex">

  <div class="basic rounded-3 p-3 d-flex justify-content-around" *ngIf="showBasic">
    <h6>Basic member</h6>
    <span>Limited options</span>
    <button (click)="onBasicClick()" type="button" class="block btn-white">FREE</button>
  </div>

  <div class="pro rounded-3 p-3">
      <h6>Pro member</h6>
      <span>Unlimited options</span>

      <div class="main-offer d-flex flex-column py-3">
        <button [ngClass]="{ 'active' : selected == 1 }"  (click)="onBillableClick(prices.optionOneMonths,prices.optionOnePrice, 1)" type="button" class="block btn-blue">{{prices.optionOneMonths}} Months for {{prices.optionOnePrice}}$</button>
        <button [ngClass]="{ 'active' : selected == 2 }" (click)="onBillableClick(prices.optionTwoMonths,prices.optionTwoPrice, 2)" type="button" class="block btn-blue">{{prices.optionTwoMonths}} Months for {{prices.optionTwoPrice}}$</button>
      </div>

      <div class="best-value py-3">
        <button [ngClass]="{ 'active' : selected == 3 }" (click)="onBillableClick(prices.optionThreeMonths,prices.optionThreePrice, 3)" type="button" class="block btn-yellow">{{prices.optionThreeMonths}} Months for {{prices.optionThreePrice}}$</button>
        <span>BEST VALUE</span>
      </div>

      <div *ngIf="selected">
        <ngx-paypal [config]="payPalConfig"></ngx-paypal>
      </div>
  </div>



</div>
