import { Component, Input, OnInit } from '@angular/core';
import { TeachingLanguage } from 'src/app/core/models/member';

@Component({
  selector: 'app-pricing-table',
  templateUrl: './pricing-table.component.html',
  styleUrls: ['./pricing-table.component.scss']
})
export class PricingTableComponent implements OnInit {

  @Input() teachingLanguages : TeachingLanguage[];

  isTLsEmpty: boolean = false;

  constructor() { }

  ngOnInit(): void {
    console.log(this.teachingLanguages);
    
    if(
        !Array.isArray(this.teachingLanguages) || 
        this.teachingLanguages.length < 1 || 
        !this.teachingLanguages.some(tl => tl.teachingLanguageItems?.length > 0)
      ) {
      this.isTLsEmpty = true;
    }
  }
}