import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from 'src/app/core/services/account.service';
import { ApiService } from 'src/app/core/services/api.service';
import { AppConfig } from 'src/assets/app.config';

@Component({
  selector: 'app-pro-prices',
  templateUrl: './pro-prices.component.html',
  styleUrls: ['./pro-prices.component.scss']
})
export class ProPricesComponent implements OnInit {
  private paypalClientId: string = "";
  prices: any;
  selected: number;
  public payPalConfig? : IPayPalConfig;

  @Input() showBasic = false;
  @Output() onPaypalError: EventEmitter<{paypalError: boolean}> = new EventEmitter();
  @Output() onBasicClickOutput: EventEmitter<any> = new EventEmitter();

  constructor(
    private _appConfig: AppConfig,
    private _apiService: ApiService,
    private _toastrService: ToastrService,
    private _accountService: AccountService,
    private _translocoService: TranslocoService
  ) { }

  ngOnInit(): void {
    this.prices = this._appConfig.prices;
    this.paypalClientId=this._appConfig.paypalClientId;
    this.initConfig();
  }


  onBillableClick(months: number, price: number, option: number) {
    this.selected = option;
  }

  onBasicClick() {
    this.onBasicClickOutput.emit();
  }

  private initConfig(): void {
    this.payPalConfig = {
        clientId: this.paypalClientId ,
        createOrderOnServer: (data) =>
          this._apiService.post('paypalCreateOrder', { paymentSubscriptionId : this.selected})
          .toPromise()
          .then((order) => {
            return order.orderId;
          }).catch(err => this.onPaypalError.emit({ paypalError: true})),
        authorizeOnServer: (approveData, actions) => {
          return this._apiService.post('authorizeOrder', { orderId: approveData.orderID}).toPromise().then(
            res => {
              this._accountService.logout(true);
              this._toastrService.success(this._translocoService.translate('paypal-transaction-authorized-success'));
            }
            );
        },
        onCancel: (data, actions) => {
          this._toastrService.error('Paypal transaction canceled.')
        },
        onError: err => {
          this.onPaypalError.emit({ paypalError: true});
        },
        style: {
          label: 'checkout',
          layout: 'vertical'
        },
        fundingSource: "PAYPAL",
    };
  }

}
