import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Filter, FilterItem, SortOption } from '../models/filter';
import { CoachingLanguageFilter, ContactOption, LanguageLevel, PaymentOption, SocialNetwork, SpecializedClassFilter, TeachingLanguageFilter } from '../models/member';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  constructor(private _apiService: ApiService, private _http: HttpClient) {}

  getAllProfessorFilters() {
    return this._apiService.get('professorsFilters').pipe(
      map((data) => {
        const filters: any[] = [];
        data.forEach((filterGroup: any) => {
          filters.push({
            id: filterGroup.id,
            name: filterGroup.name,
            order_by: filterGroup.id,
            is_visible: true,
            collapse: true,
            count: filterGroup.filterValues.length,
            values: filterGroup.filterValues.map((filter: any) => {
              return {
                id: filter.id,
                group_id: filterGroup.id,
                name: filter.name,
                order_by: filter.id,
                is_visible: false,
                checked: false,
              } as FilterItem;
            }),
          } as Filter);
        });
        return filters;
      })
    );
  }

  getAllProfessorSortOptions() {
    return this._apiService
      .get('professorsSortOptions')
      .pipe(map((data) => data[0].filterValues as SortOption[]));
  }

  getAllTeachingLanguages() {
    return this._apiService.get('teachingLanguages').pipe(map((data) => data[0].filterValues as TeachingLanguageFilter[]));
  }

  getAllCoachingLanguages() {
    return this._apiService.get('coachingLanguages').pipe(map((data) => data[0].filterValues as CoachingLanguageFilter[]));
  }

  getAllSpecializedClasses() {
    return this._apiService.get('specializedClasses').pipe(map((data) => data[0].filterValues as SpecializedClassFilter[]));
  }

  getAllLanguageLevels() {
    return this._apiService.get("languageLevels").pipe(map((data) => data[0].filterValues as LanguageLevel[]));
  }

  getAllSocialNetworks() {
    return this._apiService.get("socialNetworks").pipe(map((data) => data[0].filterValues as SocialNetwork[]));
  }

  getAllPaymentOptions() {
    return this._apiService.get("paymentOptions").pipe(map((data) => data[0].filterValues as PaymentOption[]));
  }

  getAllContactFormOptions() {
    return this._apiService.get("contactFormOptions").pipe(map((data) => data[0].filterValues as ContactOption[]))
  }
}
