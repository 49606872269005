import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {

  @Input() labelText: string;
  @Output() onValueChange = new EventEmitter<any>();

  constructor() { }

  inputValue: boolean;

  ngOnInit(): void {
  }

  onToggleSwitch() {
    this.onValueChange.emit(this.inputValue)
  }
}
