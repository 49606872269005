import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account.service';

@Component({
  selector: 'app-professor-register',
  templateUrl: './professor-register.component.html',
  styleUrls: ['./professor-register.component.scss']
})
export class ProfessorRegisterComponent implements OnInit {

  private openProfessorRegisterModalSubscription!: Subscription;
  @Input() openProfessorRegisterModalEvent!: Observable<void>;

  @ViewChild("professorRegisterModal") professorRegisterModal!: NgbModalRef;
  professorRegisterModalReference!: any;

  @ViewChild("confirmVerificationCodeModal") confirmVerificationCodeModal!: NgbModalRef;
  confirmVerificationCodeModalReference!: any;

  @ViewChild("resendVerificationCodeModal") resendVerificationCodeModal!: NgbModalRef;
  resendVerificationCodeModalReference!: any;

  @Output() openLoginModalFromRegister = new EventEmitter<{event: Event, parameters: any}>();

  constructor(
    private _modalService: NgbModal,
    private _accountService: AccountService,
    public translocoService: TranslocoService,
    private _toastrService: ToastrService,
    private _router: Router) { }

  isRegisterSpinnerVisible: boolean = false;
  isRegisterButtonDisabled: boolean = false;

  ngOnInit(): void {

    this.openProfessorRegisterModalSubscription = this.openProfessorRegisterModalEvent.subscribe(() => {

      this.openModal(this.professorRegisterModal);

    });

  }

  ngOnDestroy() {

    this.openProfessorRegisterModalSubscription.unsubscribe();

  }

  openModal(content: any) {

    this.professorRegisterModalReference = this._modalService.open(

      content,

      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        modalDialogClass: "professor-register-modal",
        backdropClass: "professor-register-modal-backdrop"

      });

    this.professorRegisterModalReference.result.then((result: any) => {

    }, (reason: any) => {

      this.resetForm(this.professorRegisterForm);

      console.log("Professor register modal closed");

    });

  }

  passwordMatchingValidatior: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');

    return password?.value === confirmPassword?.value ? null : { notmatched: true };
  };

  professorRegisterForm: UntypedFormGroup = new UntypedFormGroup({

    email: new UntypedFormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
    password: new UntypedFormControl('', [Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$^+=!*()@%&]).{8,}$")]),
    confirmPassword: new UntypedFormControl('', [Validators.required])

  }, { validators: this.passwordMatchingValidatior });

  verifyCodeForm: UntypedFormGroup = new UntypedFormGroup({

    verificationCode: new UntypedFormControl('', [Validators.required])

  }, { validators: this.passwordMatchingValidatior });

  onClickRegister() {
    this.isRegisterSpinnerVisible = true;
    this.isRegisterButtonDisabled = true;

    let formValues = this.professorRegisterForm.value;

    this._accountService.professorRegister(formValues)
    .pipe(finalize(() => {
      this.isRegisterSpinnerVisible = false;
      this.isRegisterButtonDisabled = false;
    }))
    .subscribe(
      (response) => {
        if(response === "email-alredy-exists") {
          this._toastrService.error("Some user is already registered on this email address.")
        } else if(response.stage && response.stage === "VerificationMail"){
          this._accountService.logout(true);
          this._toastrService.success(this.translocoService.translate('msg-registration-continue'));
          this.professorRegisterModalReference.close();

          const formData = this.professorRegisterForm.getRawValue();

          const loginParams = {
            email: formData.email,
            password: formData.password
          }

          this.openLoginModalFromRegister.emit({event: event!, parameters: loginParams});
        }
      }
    );
  }

  confirmVerificationCode() {

    let verificationCode = this.verifyCodeForm.getRawValue().verificationCode;;

    let userEmail = this.professorRegisterForm.getRawValue().email;

    let confirmVerificationCodeDTO = {

      "email": userEmail,
      "verificationCode": verificationCode

    }


  }

  resendVerificationCode() {

    this.resendVerificationCodeModalReference.close();

    this.confirmVerificationCodeModalReference = this._modalService.open(

      this.confirmVerificationCodeModal,

      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        modalDialogClass: "confirm-verification-code-modal",
        backdropClass: "confirm-verification-d-modal-backdrop"
      });

    this.confirmVerificationCodeModalReference.result.then((result: any) => {

    }, (reason: any) => {

      this.resetForm(this.verifyCodeForm);

      console.log("Confirm verification code modal closed");

    });


  }

  resetForm(form: UntypedFormGroup) {

    form.reset();

    form.markAsPristine();

    form.markAsUntouched();

  }

}
