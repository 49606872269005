
<div class="d-flex flex-row justify-content-around">

    <h6 class="label-text">{{labelText}}</h6>

    <label class="switch">
        <input type="checkbox" (change)="onToggleSwitch()" [(ngModel)]="inputValue">
        <span class="slider round"></span>
    </label>
    
</div>