<ng-template #loginModal let-modal>
    <div class="modal-header-custom">
        <div class="modal-header-close-button-wrapper">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                    class="ButtonRow-module__closeIconInner___2tMz9">
                    <path fill="#202125" fill-rule="evenodd"
                        d="M19.854 19.146a.5.5 0 01-.707.707L12 12.707l-7.146 7.146a.5.5 0 11-.708-.707L11.293 12 4.147 4.855a.5.5 0 01.707-.708l7.145 7.147 7.147-7.146a.5.5 0 01.707.707L12.707 12l7.146 7.146z">
                    </path>
                </svg>
            </button>

        </div>
        <h4 class="modal-title" id="modal-basic-title">{{'label-login' | transloco }}</h4>
        <p class="modal-title-subheader">{{ 'label-or' | transloco }} <a (click)="onClickOpenStudentRegisterModal()"
            class="create-account">{{ 'label-create-new-account' | transloco }}</a>
        </p>
    </div>
    <div class="modal-body">
        <form [formGroup]="loginForm">
            <div class="form-group">
                <div class="form-floating mb-3 has-icon">
                    <i class="ai-mail"></i>
                    <input autocomplete="one-time-code" name="email" formControlName="email" type="email" email
                        class="form-control" id="floatingInput" placeholder="name@example.com">
                    <label for="floatingInput">{{ 'label-email' | transloco}}</label>
                </div>
            </div>
            <div class="form-group mb-3">
                <div class="form-floating has-icon">
                    <i class="ai-lock"></i>
                    <input autocomplete="one-time-code" name="password" formControlName="password" type="password"
                        class="form-control" id="floatingPassword" placeholder="'label-password' | transloco">
                    <label for="floatingPassword">{{'label-password' | transloco}}</label>
                </div>
            </div>
            <div *ngIf="isInvalidEmailPassword" class="invalid-email-password mb-3">{{ 'label-invalid-password' | transloco}}
            </div>
            <button type="submit" (click)="onClickLogin()" [disabled]="!loginForm.valid || isLoginButtonDisabled"
                class="btn btn-primary login-button">
                <span *ngIf="!isLoginSpinnerVisible">{{'label-login' | transloco }}</span>
                <div *ngIf="isLoginSpinnerVisible" class="login-button-spinner spinner-border text-light" role="status">
                </div>
            </button>
        </form>
    </div>
    <div class="modal-footer">
        <small class="forgot-password" (click)="onForgotPassword()">{{ 'label-forgot-password' | transloco }} </small>
    </div>
</ng-template>

<ng-template  #forgotPasswordModal let-modal>
    <div class="modal-header-custom">
        <div class="modal-header-close-button-wrapper">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                    class="ButtonRow-module__closeIconInner___2tMz9">
                    <path fill="#202125" fill-rule="evenodd"
                        d="M19.854 19.146a.5.5 0 01-.707.707L12 12.707l-7.146 7.146a.5.5 0 11-.708-.707L11.293 12 4.147 4.855a.5.5 0 01.707-.708l7.145 7.147 7.147-7.146a.5.5 0 01.707.707L12.707 12l7.146 7.146z">
                    </path>
                </svg>
            </button>
        </div>
        <h4 class="modal-title" id="modal-basic-title">{{ 'label-forgot-password' | transloco }}</h4>
    </div>
    <div class="modal-body">
        <form [formGroup]="forgotPasswordForm">
            <div class="form-group">
                <div class="form-floating mb-3 has-icon">
                    <i class="ai-mail"></i>
                    <input autocomplete="one-time-code" name="email" formControlName="email" type="email" email
                        class="form-control" id="floatingInput" placeholder="name@example.com">
                    <label for="floatingInput">{{ 'label-email' | transloco}}</label>
                </div>
            </div>
            <div *ngIf="forgotPasswordForm.controls.email.invalid && (forgotPasswordForm.controls.email.dirty || forgotPasswordForm.controls.email.touched)"
                class="form-invalid-input-alert mb-3">
                <small *ngIf="forgotPasswordForm.controls.email.errors?.required">
                  {{ 'validation-email-required' | transloco }}
                </small>
                <small *ngIf="forgotPasswordForm.controls.email.errors?.email">
                  {{ 'validation-email-invalid' | transloco }}
                </small>
            </div>
            <button type="button" (click)="onClickForgotPassword()" [disabled]="!forgotPasswordForm.valid" class="btn btn-primary login-button">
                <span *ngIf="!isLoginSpinnerVisible">{{ 'confirmation-code-reset-label' | transloco}}</span>
                <div *ngIf="isLoginSpinnerVisible" class="login-button-spinner spinner-border text-light" role="status">
                </div>
            </button>
        </form>
    </div>
</ng-template>

<ng-template  #newPasswordModal let-modal>
    <div class="modal-header-custom">
        <div class="modal-header-close-button-wrapper">
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                    class="ButtonRow-module__closeIconInner___2tMz9">
                    <path fill="#202125" fill-rule="evenodd"
                        d="M19.854 19.146a.5.5 0 01-.707.707L12 12.707l-7.146 7.146a.5.5 0 11-.708-.707L11.293 12 4.147 4.855a.5.5 0 01.707-.708l7.145 7.147 7.147-7.146a.5.5 0 01.707.707L12.707 12l7.146 7.146z">
                    </path>
                </svg>
            </button>
        </div>
        <h4 class="modal-title" id="modal-basic-title">{{ 'new-password-label' | transloco}}</h4>
    </div>
    <div class="modal-body">
        <form [formGroup]="newPasswordForm">
            <div class="form-group">
                <div class="form-floating mb-3 has-icon">
                    <i class="ai-mail"></i>
                    <input autocomplete="one-time-code" name="email" formControlName="email" type="email" email
                        class="form-control" id="floatingInput" placeholder="name@example.com">
                    <label for="floatingInput">{{ 'label-email' | transloco}}</label>
                </div>
            </div>
            <div *ngIf="newPasswordForm.controls.email.invalid && (newPasswordForm.controls.email.dirty || newPasswordForm.controls.email.touched)"
                class="form-invalid-input-alert mb-3">
                <small *ngIf="newPasswordForm.controls.email.errors?.required">
                  {{ 'validation-email-required' | transloco }}
                </small>
                <small *ngIf="newPasswordForm.controls.email.errors?.email">
                  {{ 'validation-email-invalid' | transloco }}
                </small>
            </div>
            <div class="form-group mb-3">
                <div class="form-floating has-icon">
                    <i class="ai-lock"></i>
                    <input autocomplete="one-time-code" formControlName="verificationCode" name="confirmationCode"
                        type="text" class="form-control" id="confirmationCode" placeholder="Confirmation code">
                    <label for="confirmationCode">{{ 'stage-label-confiramtion-code' | transloco }}</label>
                </div>
            </div>
            <div *ngIf="newPasswordForm.controls.verificationCode.invalid && (newPasswordForm.controls.verificationCode.dirty || newPasswordForm.controls.verificationCode.touched)"
                class="form-invalid-input-alert mb-3">
                <small *ngIf="newPasswordForm.controls.verificationCode.errors?.required">
                  {{ 'validation-verification-code-required' | transloco }}
                </small>
            </div>
            <div class="form-group mb-3">
                <div class="form-floating has-icon">
                    <i class="ai-lock"></i>
                    <input autocomplete="one-time-code" name="password" formControlName="password" type="password"
                        class="form-control" id="password" placeholder="'label-password' | transloco">
                    <label for="password">{{ 'new-password-label-2' | transloco}}</label>
                </div>
            </div>
            <div *ngIf="newPasswordForm.controls.password.invalid && (newPasswordForm.controls.password.dirty || newPasswordForm.controls.password.touched)"
                class="form-invalid-input-alert mb-3">
                <small *ngIf="newPasswordForm.controls.password.errors?.required">
                  {{ 'validation-new-password-required' | transloco }}
                </small>
                <small *ngIf="newPasswordForm.controls.password.errors?.pattern">
                 {{ 'validation-password-complexity' | transloco }}
                </small>
            </div>
            <div class="form-group mb-3">
                <div class="form-floating has-icon">
                    <i class="bi ai-lock"></i>
                    <input autocomplete="one-time-code" name="confirmPassword" formControlName="confirmPassword"
                        type="password" class="form-control" id="confirmPassword" placeholder="'newp-password-confirm-label' | transloco">
                    <label for="confirm-password">{{ 'newp-password-confirm-label' | transloco}}</label>
                </div>
                <div *ngIf="newPasswordForm.controls.confirmPassword.invalid && (newPasswordForm.controls.confirmPassword.dirty || newPasswordForm.controls.confirmPassword.touched)"
                    class="form-invalid-input-alert">
                    <small *ngIf="newPasswordForm.controls.confirmPassword.errors?.required">
                      {{ 'validation-new-password-required' | transloco }}
                    </small>
                </div>
                <div *ngIf="newPasswordForm.invalid && (newPasswordForm.controls.confirmPassword.dirty || newPasswordForm.controls.confirmPassword.touched)"
                    class="form-invalid-input-alert">
                    <small *ngIf="newPasswordForm.errors">
                      {{ 'validation-passwords-must-match' | transloco }}
                    </small>
                </div>
            </div>
            <button type="button" (click)="onClickSaveNewPassword()" [disabled]="!newPasswordForm.valid" class="btn btn-primary login-button">
                <span *ngIf="!isLoginSpinnerVisible">{{ 'btn-new-password-save' | transloco }}</span>
                <div *ngIf="isLoginSpinnerVisible" class="login-button-spinner spinner-border text-light" role="status">
                </div>
            </button>
        </form>
    </div>
</ng-template>

<a class="nav-link-style fs-sm text-nowrap ms-grid-gutter" (click)="openModal(loginModal)" style="cursor: pointer;">{{'label-login' | transloco }}</a>
