<div class="compact-card-wrapper">

    <div class="main-card-top">

        <div class="image-wrapper">

            <img class="professor-image" src="{{professor.imageUrl}}"
            alt="image">

        </div>

        <div class="info-wrapper">

            <div class="main-info">

                <span class="professor-name">
                    {{ professorName | truncate:[ 21, '...' ] }}
                </span>

                <div *ngIf="!isPro; else proBedge" class="professor-role">
                    <span class="badge bg-primary">
                        {{ 'teacher-badge-label-basic' | transloco }}
                    </span>
                </div>

                <ng-template #proBedge>
                    <div class="professor-role">
                        <span class="badge bg-danger">
                          {{ 'teacher-badge-label-pro' | transloco }}
                        </span>
                    </div>
                </ng-template>

                <div class="rating-wrapper" [ngClass]="isPro? 'pro' : ''">

                    <ng-container *ngIf="professorCurrentRating && professorCurrentRating > 1; else noRating">

                        <ngb-rating class="rating custom-rating" [max]="maxRating" [rate]="professorCurrentRating"
                            [readonly]="true">
                        </ngb-rating>

                        <span class="rating-value">
                            {{ professorCurrentRating }}
                        </span>

                    </ng-container>

                    <ng-template #noRating>

                        <span class="no-rating">
                            TBD
                        </span>

                    </ng-template>

                </div>

            </div>

        </div>

        <div class="add-to-favourites-button-wrapper" [ngClass]="isPro? 'pro' : ''">
            <ng-container *ngIf="dataStorageService.studentsFavouritesTeachers | async as favouriteTeachers">
                <like-button [isActive]="checkIfTeacherIsFavourite(favouriteTeachers)" [userId]="professor.professorId"></like-button>
            </ng-container>
        </div>

    </div>

    <hr class="rounded">

    <div class="main-card-bottom mt-1">

        <div class="teaching-languages-wrapper">

            <span class="teaching-languages-heading">
                {{ 'label-teaches' | transloco }}
            </span>

            <div class="teaching-languages-content">

                <div class="label_separator-wrapper"
                    *ngFor="let teachingLanguage of professorTeachingLanguages; let i = index">

                    <div class="teaching-language-label">

                        <span>{{ teachingLanguage.languageValue }}</span>

                    </div>

                    <div *ngIf="i !== (professorTeachingLanguages.length-1)" class="separator-wrapper">
                        <span class="separator"></span>
                    </div>

                </div>

            </div>

        </div>

        <div class="coaching-languages-wrapper">

            <span class="coaching-languages-heading">
              {{ 'label-speaks' | transloco }}
            </span>

            <div class="coaching-languages-content">

                <div class="label_separator-wrapper"
                    *ngFor="let coachingLanguage of professorCoachingLanguages; let i = index">

                    <div class="coaching-language-label">

                        <span>{{ coachingLanguage.languageValue }}</span>

                    </div>

                    <div *ngIf="i !== (professorCoachingLanguages.length-1)" class="separator-wrapper">
                        <span class="separator"></span>
                    </div>

                </div>

            </div>

        </div>

        <ng-container *ngIf="accountService.tokenData$ | async as currentUserData">
            <app-send-message-btn *ngIf="currentUserData.isStudent"
            [professorId]="professor.professorId" [studentId]="currentUserData.id"
            (onChatCreated)="afterChatCreated($event, professor.firstName, professor.lastName, professor.professorId, currentUserData.id)"></app-send-message-btn>
        </ng-container>

    </div>

</div>
