export interface Pagination {
    // currentPage: number;
    // itemsPerPage: number;
    // totalItems: number;
    // totalPages: number;

    // TO DO: Change this. It's currently configured to accepts key from json server
    value: any;
}

export class PaginatedResult<T> {
    result: T;
    pagination: Pagination;
}