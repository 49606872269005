import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MemberParams } from './core/models/memberParams';
import { AccountService } from './core/services/account.service';
import { MemberService } from './core/services/member.service';
import { SpinnerService } from './core/services/spinner.service';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'My Language Guru';

  constructor(
    private _cdRef: ChangeDetectorRef,
    private _accountService: AccountService,
    public spinnerService: SpinnerService,
    private _memberService: MemberService,
    private _translocoService: TranslocoService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit() {

    const token = this._accountService.loggedIn();

    const selectedLanguage = this._accountService.getSelectedLanguage();
    const activeLanguage = this._translocoService.getActiveLang();

    if(selectedLanguage != activeLanguage) {
      this._translocoService.setActiveLang(selectedLanguage);
    }

    if (token) {

      let decodedTokenValues = this._accountService.getDecodedAccessToken(token);

      this._accountService.getUserFromToken(decodedTokenValues);

      // this.presence.createHubConnection(user);

    }

    this.setMetaTags();

  }

  ngAfterViewChecked() {

    this._cdRef.detectChanges();

  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
 
  setMetaTags(){
    
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      
        var rt = this.getChild(this.activatedRoute)
        rt.data.subscribe(data => {
          this.titleService.setTitle(data.title)
 
          if (data.descrption) {
            this.metaService.updateTag({ name: 'description', content: data.descrption })
          } else {
            this.metaService.removeTag("name='description'")
          }
 
          if (data.robots) {
            this.metaService.updateTag({ name: 'robots', content: data.robots })
          } else {
            this.metaService.updateTag({ name: 'robots', content: "follow,index" })
          }
 
          if (data.ogUrl) {
            this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
          } else {
            this.metaService.updateTag({ property: 'og:url', content: this.router.url })
          }
 
          if (data.ogTitle) {
            this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
          } else {
            this.metaService.removeTag("property='og:title'")
          }
 
          if (data.ogDescription) {
            this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
          } else {
            this.metaService.removeTag("property='og:description'")
          }
 
          if (data.ogImage) {
            this.metaService.updateTag({ property: 'og:image', content: data.ogImage })
          } else {
            this.metaService.removeTag("property='og:image'")
          }
 
 
        })
 
      })
  }
}
