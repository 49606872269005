export const URL_LIST = {
    "login": "Account/login",
    "resetPasswordToken": "Account/reset-password-token",
    "resetUserPassword": "Account/reset-password",
    "resendVerificationCodeProfessor": "Professor/resend-email-verification-code",
    "resendVerificationCodeStudent": "Student/resend-email-verification-code",
    "studentRegister" : "Student",
    "professorRegister" : "Professor",
    "studentVerifyEmail" : "Student/verify-email",
    "professorVerifyEmail" : "Professor/verify-email",
    "professorsFilters" : "Filter/all",
    "languageLevels" : "Filter/language-levels",
    "professorsSortOptions" : "Filter/professor-sort-options",
    "socialNetworks": "Filter/social-networks",
    "professorSocialNetwork": "Professor/ProfessorSocialNetwork",
    "professorPaymentOption": "Professor/ProfessorPaymentOption",
    "updateProfessorsDescription": "Professor/Description",
    "updateProfessorsGeneralData" : "Professor/General",
    "updateProfessorBasicInfo" : "Professor/BasicInfo",
    "updateProfessorShortDescription" : "Professor/ShortDescription",
    "updateStudentGeneralData" : "Student",
    "deleteProfessorTeachingLanguage" : "Professor/ProfessorTeachingLanguage",
    "deleteProfessorCoachingLanguage" : "Professor/ProfessorCoachingLanguage",
    "deleteProfessorSpecializedClass" : "Professor/ProfessorPurposeLanguage",
    "teachingLanguages" : "Filter/teaching-languages",
    "uploadProfessorProfilePicture": "Professor/profile-picture",
    "coachingLanguages" : "Filter/coaching-languages",
    "specializedClasses" : "Filter/specialized-classes",
    "paymentOptions": "Filter/payment-options",
    "createProfessorCL" : "Professor/ProfessorCoachingLanguage",
    "createProfessorSC" : "Professor/ProfessorPurposeLanguage",
    "followProfessor" : "Student/StudentFollowProfessor",
    "unfollowProfessor" : "Student/StudentFollowProfessor",
    "professorFollowStudent" : "Professor/ProfessorFollowStudent",
    "studentFavouriteCollection" : "Professor/ProfessorFollowers",
    "studentSocalNetwork": "Student/StudentSocialNetwork",
    "professorCalendar": "Professor/ProfessorCalendar",
    "createChatConversation": "Message/conversation",
    "getChatCards": "Message/chat-cards",
    "getConversationByParticipantsIds": "Message/conversation-by-participants-id",
    "getConversationByConversationId": "Message/conversation-by-id",
    "sendMessage": "Message",
    "professorFollowersNumber": "Professor/NumberOfProfessorFollowers",
    "paypalCreateOrder": "Paypal/create-order",
    "authorizeOrder": "Paypal/capture-order",
    "contactFormOptions": "Filter/contact-categories",
    "contactMessage": "ContactMessage/ContactMessage",
    "StudentRatesProfessor": "Student/StudentRatesProfessor"
}
