export class AppConfig {
  apiUrl: string;
  production: boolean;
  prices: Prices;
  paypalClientId: string;
}

type Prices = {
  optionOnePrice: number,
  optionOneMonths: number,
  optionTwoPrice: number,
  optionTwoMonths: number,
  optionThreePrice: number,
  optionThreeMonths: number,
}
