import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IdentityRoutingModule } from './identity-routing.module';
import { LoginComponent } from './login/login.component';
import { StudentRegisterComponent } from './student-register/student-register.component';
import { ProfessorRegisterComponent } from './professor-register/professor-register.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [
    LoginComponent,
    StudentRegisterComponent,
    ProfessorRegisterComponent
  ],
  imports: [
    CommonModule,
    IdentityRoutingModule,
    ReactiveFormsModule,
    TranslocoModule
  ],
  exports: [
    LoginComponent,
    StudentRegisterComponent,
    ProfessorRegisterComponent
  ]
})
export class IdentityModule { }
