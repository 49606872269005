import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-become-pro',
  templateUrl: './become-pro.component.html',
  styleUrls: ['./become-pro.component.scss']
})
export class BecomeProComponent implements OnInit {

  constructor() { }

  @Input() title: string | undefined;
  @Input() message: string | undefined;


  ngOnInit(): void {
  }

  openProGuide() {
    alert("NOT IMPLEMENTED");
  }

}
