import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { ConversationIds } from 'src/app/content/pages/messages/messages.models';
import { AppConfig } from 'src/assets/app.config';
import { ProfessorMember, StudentMember } from '../models/member';
import { MemberParams } from '../models/memberParams';
import { PaginatedResult } from '../models/pagination';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root',
})
export class MemberService {

    private baseURL: string = "";

    // private allMembers: any[] = [];
    private studentMembers: StudentMember[] = [];
    private professorMembers: ProfessorMember[] = [];

    // allMembersPaginatedResult: PaginatedResult<any[]> = new PaginatedResult<any[]>();
    studentMembersPaginatedResult: PaginatedResult<StudentMember[]> = new PaginatedResult<StudentMember[]>();
    professorMembersPaginatedResult: PaginatedResult<ProfessorMember[]> = new PaginatedResult<ProfessorMember[]>();

    constructor(
        private _httpClient: HttpClient,
        private _toastr: ToastrService,
        private _apiService: ApiService,
        private _appConfig: AppConfig,
        public translocoService: TranslocoService,
    ) {
        this.baseURL = _appConfig.apiUrl;
     }

    getStudentMembers(currentPage?: number, itemsPerPage?: number) {

        let httpParams = new HttpParams();

        if (currentPage !== null && itemsPerPage !== null) {

            httpParams = httpParams.append("Pagination.CurrentPageNumber", currentPage!.toString());
            httpParams = httpParams.append("Pagination.NumberPerPage", itemsPerPage!.toString());

        }

        return this._httpClient.get<StudentMember[]>(this.baseURL + `Student`, {

            observe: 'response',
            params: httpParams

        }).pipe(

            map((response: any) => {

                console.log(response);

                this.studentMembersPaginatedResult.result = response.body;

                /*
                If pagination information like etc. TotalItemsCount comes from headers:
                Implement different pagination key name depending on the api result. Json Server uses x-total-count for total element count
                */
                if (response.headers.get("Pagination") !== null) {
                    this.studentMembersPaginatedResult.pagination = JSON.parse(response.headers.get("Pagination"));
                }

                return this.studentMembersPaginatedResult;
            }),

            catchError(error => {

                if (error.error instanceof ErrorEvent) {

                    console.log("Error!");

                    this._toastr.error(this.translocoService.translate('msg-something-went-wrong'));

                } else {

                    console.log("Error!");

                    this._toastr.error(this.translocoService.translate('msg-something-went-wrong'));

                }

                return of(false);
            })
        )
    }

    getStudentMember(id: string) {

        return this._httpClient.get<any>(this.baseURL + `Student/${id}`, { observe: 'response' }).pipe(

            map((response: any) => {

                console.log(response);

                return response.body;
            }),

            catchError(error => {

                if (error.error instanceof ErrorEvent) {

                    console.log("Error!");

                    this._toastr.error(this.translocoService.translate('msg-something-went-wrong'));

                } else {

                    console.log("Error!");

                    this._toastr.error(this.translocoService.translate('msg-something-went-wrong'));

                }

                return of(false);
            })
        )
    }

    getProfessorMembers(memberParams: MemberParams) {

        let httpParams = new HttpParams();

        if (memberParams.currentPage !== null
            && memberParams.itemsPerPage !== null
            && memberParams.sortBy !== null) {

            httpParams = httpParams.append("Pagination.CurrentPageNumber", memberParams.currentPage!.toString());
            httpParams = httpParams.append("Pagination.NumberPerPage", memberParams.itemsPerPage!.toString());
        }

        if(memberParams.sortBy && memberParams.sortBy !== 0){
            httpParams = httpParams.append("Pagination.SortBy", memberParams.sortBy.toString());
        }

        if(memberParams.fullName && memberParams.fullName !== ''){
            httpParams = httpParams.append("FullName", memberParams.fullName);
        }

        if(memberParams.filterFollowingProfessors) {
            httpParams = httpParams.append("FilterFollowingProfessors", 'true');
        }

        if(memberParams.filters && memberParams.filters.length > 0) {

            memberParams.filters.forEach(filterGroup => {
                switch (filterGroup.id) {
                    case 1:
                        filterGroup.values.forEach(filter => httpParams = httpParams.append("LanguageLevels", filter.id.toString()));
                        break;
                    case 2:
                        filterGroup.values.forEach(filter => httpParams = httpParams.append("PurposeLanguages", filter.id.toString()));
                        break;
                    case 3:
                        filterGroup.values.forEach(filter => httpParams = httpParams.append("SocialNetworks", filter.id.toString()));
                        break;
                    case 4:
                        filterGroup.values.forEach(filter => httpParams = httpParams.append("TeachingLanguage", filter.id.toString()));
                        break;
                    case 5:
                        filterGroup.values.forEach(filter => httpParams = httpParams.append("CoachingLanguage", filter.id.toString()));
                        break;
                }
            });
        }

        return this._httpClient.get<ProfessorMember[]>(this.baseURL + `Professor`, {

            observe: 'response',
            params: httpParams

        }).pipe(

            map((response: any) => {

                this.professorMembersPaginatedResult.result = response.body.professors;

                /*
                If pagination information like etc. TotalItemsCount comes from headers:
                Implement different pagination key name depending on the api result. Json Server uses x-total-count for total element count
                */
                // if (response.headers.get("x-total-count") !== null) {
                if (response.body.count !== null || response.body.count > 0) {

                    /*
                    Implement different pagination key name depending on the api result. Json Server uses x-total-count for total element count
                    */
                    // this.professorMembersPaginatedResult.pagination = JSON.parse(response.headers.get("x-total-count"));
                    this.professorMembersPaginatedResult.pagination = response.body.count;

                }

                return this.professorMembersPaginatedResult;
            }),

            catchError(error => {

                if (error.error instanceof ErrorEvent) {

                    console.log("Error!");

                    this._toastr.error(this.translocoService.translate('msg-something-went-wrong'));

                } else {

                    console.log("Error!");

                    this._toastr.error(this.translocoService.translate('msg-something-went-wrong'));

                }

                return of(false);
            })
        )
    }

    getProfessorMember(id: string) {

        // let httpParams = new HttpParams();
        // httpParams = httpParams.set("professorId", id);

        return this._httpClient.get<any>(this.baseURL + `Professor/${id}`, {

            observe: 'response',
            // params: httpParams

        }

        ).pipe(

            map((response: any) => {

                return response.body;
            }),

            catchError(error => {

                if (error.error instanceof ErrorEvent) {

                    console.log("Error!");

                    this._toastr.error(this.translocoService.translate('msg-something-went-wrong'));

                } else {

                    console.log("Error!");

                    this._toastr.error(this.translocoService.translate('msg-something-went-wrong'));

                }

                return of(false);
            })
        )
    }

    getStudentsFavouriteTeachers() : Observable<any> {
        const params = new MemberParams();

        params.filterFollowingProfessors = true;
        params.currentPage = null;
        params.itemsPerPage = null;

        return this.getProfessorMembers(params);
    }

    updateProfessorGeneralData(generalData: any) {

        return this._apiService.put('updateProfessorsGeneralData', generalData);

    }

    updateStudentGeneralData(generalData: any) {
        return this._apiService.put('updateStudentGeneralData', generalData);
    }

    updateProfessorBasicInfo(generalData: any) {
        return this._apiService.put('updateProfessorBasicInfo', generalData);
    }

    createProfessorTeachingLanguage(teachingLanguage: any) {

        return this._httpClient.post<any>(this.baseURL + `Professor/ProfessorTeachingLanguage`, teachingLanguage, { observe: 'response' }).pipe(

            delay(2000),

            map((response: any) => {
                return response.body;
            })
        )

    }

    createProfessorCoachingLanguage(coachingLanguage: any) {

        return this._apiService.post('createProfessorCL', coachingLanguage).pipe(
            delay(2000)
        );

    }

    createProfessorSpecializedClass(specializedClass: any) {

        return this._apiService.post('createProfessorSC', specializedClass).pipe(
            delay(2000)
        );

    }

    createProfessorTeachingLanguageItem(teachingLanguageItem: any) {

        return this._httpClient.post<any>(this.baseURL + `Professor/ProfessorTeachingLanguageItem`, teachingLanguageItem, { observe: 'response' }).pipe(

            delay(2000),

            map((response: any) => {

                return response.body;
            })
        );

    }

    putProfessorTeachingLanguage(teachingLanguage: any) {

        return this._httpClient.put<any>(this.baseURL + `Professor/ProfessorTeachingLanguage`, teachingLanguage, { observe: 'response' }).pipe(

            delay(2000),

            map((response: any) => {


                return response.body;
            }),

            catchError(error => {

                if (error.error instanceof ErrorEvent) {

                    console.log("Error!");

                    this._toastr.error(this.translocoService.translate('msg-something-went-wrong'));

                } else {

                    console.log("Error!");

                    this._toastr.error(this.translocoService.translate('msg-something-went-wrong'));

                }

                return of(false);
            })
        )

    }

    putProfessorTeachingLanguageItem(teachingLanguageItem: any) {

        return this._httpClient.put<any>(this.baseURL + `Professor/ProfessorTeachingLanguageItem`, teachingLanguageItem, { observe: 'response' }).pipe(

            delay(2000),

            map((response: any) => {

                console.log(response);

                return response.body;
            }),

            catchError(error => {

                if (error.error instanceof ErrorEvent) {

                    console.log("Error!");

                    this._toastr.error(this.translocoService.translate('msg-something-went-wrong'));

                } else {

                    console.log("Error!");

                    this._toastr.error(this.translocoService.translate('msg-something-went-wrong'));

                }

                return of(false);
            })
        )

    }

    deleteProfessorTeachingLanguage(professorTeachingLanguage: any){

        return this._apiService.delete("deleteProfessorTeachingLanguage",professorTeachingLanguage);

    }

    deleteProfessorCoachingLanguage(professorCoachingLanguage: any){

        return this._apiService.delete("deleteProfessorCoachingLanguage",professorCoachingLanguage);

    }

    deleteProfessorSpecializedClass(specializedClass: any){

        return this._apiService.delete("deleteProfessorSpecializedClass",specializedClass);

    }

    professorFollowStudent(studentId:string) {
        const payload = {
            studentId: studentId
        }

        return this._apiService.post('professorFollowStudent', payload);
    }

    professorUnfollowStudent(studentId:string) {
        const payload = {
            studentId: studentId
        }

        return this._apiService.delete('professorFollowStudent', payload);
    }

    updateProfessorsDescription(description: string) {
        const payload = {
            description: description
        }

        return this._apiService.patch("updateProfessorsDescription", payload)
        .pipe(
            map(response => {
                this._toastr.success(this.translocoService.translate('msg-full-desc-updated-success'));
                return response;
            }
        ));
    }


    updateProfessorsShortDescription(description: string) {
        const payload = {
            shortDescription: description
        }

        return this._apiService.patch("updateProfessorShortDescription", payload);
    }

    uploadProfessorProfilePicture(image: any) {
        const formData = new FormData();

        formData.append("picture", image, "profilePicture");

        return this._apiService.post("uploadProfessorProfilePicture", formData);
    }

    putProfessorSocalNetwork(payload: any) {
        return this._apiService.post("professorSocialNetwork", payload);
    }

    deleteProfessorSocialNetwork(payload: any) {
        return this._apiService.delete("professorSocialNetwork", payload);
    }

    putStudentSocalNetwork(payload: any) {
        return this._apiService.post("studentSocalNetwork", payload);
    }

    deleteStudentSocialNetwork(payload: any) {
        return this._apiService.delete("studentSocalNetwork", payload);
    }

    postProfessorPaymentOption(payload: any) {
        return this._apiService.post("professorPaymentOption", payload);
    }

    deleteProfessorPaymentOption(payload: any) {
        return this._apiService.delete("professorPaymentOption", payload);
    }

    getProfessorTimetable(professorId: any) {
        const query = {
            ProfessorId: professorId
        }

        return this._apiService.get("professorCalendar", query);
    }

    postProfessorTimetableItem(payload: any) {

        return this._apiService.post("professorCalendar",payload);
    }

    updateProfessorTimetableItem(payload: any) {
        return this._apiService.put("professorCalendar", payload);
    }

    deleteProfessorTimetableItem(payload: any) {
        return this._apiService.delete("professorCalendar", payload);
    }

    postChatConversation(payload: any) {
        return this._apiService.post("createChatConversation", payload);
    }

    getAllConversations() {
        return this._apiService.get("getChatCards");
    }

    checkIfChatExists(ids: ConversationIds) {
        return this._apiService.get("getConversationByParticipantsIds",ids);
    }

    getConversationById(conversationId: string) {
        const query = {
            conversationId: conversationId
        }

        return this._apiService.get("getConversationByConversationId", query);
    }

    sendMesssage(payload: any) {
        return this._apiService.post("sendMessage", payload);
    }

    getNumberOfFolower() {
        return this._apiService.get("professorFollowersNumber");
    }

    postContactForm(payload: any) {
        return this._apiService.post("contactMessage", payload);
    }

    getTeachersToRate() {
        return this._apiService.get("StudentRatesProfessor");
    }

    rateTeacher(payload: any) {
        return this._apiService.post("StudentRatesProfessor", payload);
    }
}
