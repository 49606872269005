import { Location } from '@angular/common';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/core/services/account.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  @Output() langChangeEvent = new EventEmitter<string>();

  defaultLanguage: string = "en";

  selectedLanguage: string;

  constructor(
    private _accountService: AccountService,
    private _location: Location,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this.selectedLanguage = this._accountService.getSelectedLanguage();
  }

  onLanguageChange() {
    let path = this._router.url.split('/');


    if(path[1] === 'de' || path[1] === 'en') {

      path[1] = this.selectedLanguage;
      
      this._location.go(path.join('/'));
    }
    
    this._accountService.changeTranslocoLanguage(this.selectedLanguage);
    this.langChangeEvent.emit(this.selectedLanguage);
  }

  
}
