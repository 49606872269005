import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  private spinnerSource = new BehaviorSubject('loading :(');
  public currentSpinnerValue$ = this.spinnerSource.asObservable();

  constructor() { }

  showSpinner() {

    this.spinnerSource.next("true");

  }

  hideSpinner() {

    this.spinnerSource.next("false");
    
  }

}
