<div class="px-2 py-4">
  <div *ngIf="name && headerText" class="card card-body border"  data-bs-toggle="collapse" attr.data-bs-target="#{{name}}" aria-expanded="false" attr.aria-controls="{{name}}">
    <h3 class="cursor-p">{{headerText}}</h3>
    <p *ngIf="!isFAQ">{{descriptionText}}</p>
    <div class="collapse" attr.id="{{name}}">
      <div class="card card-body">
        <p *ngIf="isFAQ && descriptionText">{{descriptionText}}</p>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
