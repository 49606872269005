import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IdentityModule } from '../content/pages/identity/identity.module';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavComponent } from './nav/nav.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { CoreModule } from '../core/core.module';
import { FooterComponent } from './footer/footer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { BecomeProComponent } from './become-pro/become-pro.component';
import { ProfessorCompactCardComponent } from './content/professor-compact-card/professor-compact-card.component';
import { LikeButtonComponent } from './content/like-button/like-button.component';
import { PricingTableComponent } from './content/pricing-table/pricing-table.component';
import { ToggleSwitchComponent } from './content/toggle-switch/toggle-switch.component';
import { SendMessageBtnComponent } from './content/send-message-btn/send-message-btn.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LanguageSelectorComponent } from './content/language-selector/language-selector.component';
import { ProPricesComponent } from './pro-prices/pro-prices.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { BecomeProBtnComponent } from './become-pro-btn/become-pro-btn.component';
import { CollapsableComponent } from './collapsable/collapsable.component';
import { RatingComponent } from './rating/rating.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    NavComponent,
    FooterComponent,
    SpinnerComponent,
    BecomeProComponent,
    ProfessorCompactCardComponent,
    LikeButtonComponent,
    PricingTableComponent,
    ToggleSwitchComponent,
    SendMessageBtnComponent,
    LanguageSelectorComponent,
    ProPricesComponent,
    BecomeProBtnComponent,
    CollapsableComponent,
    RatingComponent,
    ImageCropperComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    CoreModule,
    NgbModule,
    IdentityModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      timeOut: 7000
    }),
    LottieModule.forRoot({ player: playerFactory }),
    TranslocoModule,
    NgxPayPalModule,
    ImageCropperModule
  ],
  exports: [
    RouterModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    NavComponent,
    FooterComponent,
    SpinnerComponent,
    NgbModule,
    ToastrModule,
    LottieModule,
    BecomeProComponent,
    ProfessorCompactCardComponent,
    PricingTableComponent,
    ToggleSwitchComponent,
    LikeButtonComponent,
    SendMessageBtnComponent,
    ProPricesComponent,
    BecomeProBtnComponent,
    CollapsableComponent,
    RatingComponent,
    ImageCropperComponent
  ]
})
export class SharedModule { }
