import { Component, Input, OnInit } from '@angular/core';
import { TranslocoPipe, TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account.service';
import { DataStorageService } from 'src/app/core/services/data-storage/data-storage.service';
import { MemberService } from 'src/app/core/services/member.service';
import { StudentService } from 'src/app/core/services/student/student.service';

@Component({
  selector: 'like-button',
  templateUrl: './like-button.component.html',
  styleUrls: ['./like-button.component.scss']
})
export class LikeButtonComponent implements OnInit {

  @Input() isActive = false;
  @Input() userId : string;
  @Input() followingCollection : Array<any>;

  currentUserData : any;
  currentUserData$ : any;



  isDisabled = false;

  constructor(
    private studentService : StudentService,
    private toastrService: ToastrService,
    private accountService: AccountService,
    private memberService: MemberService,
    private dataStorageService : DataStorageService,
    private translocoService: TranslocoService
  ) { }

  ngOnInit(): void {
    this.currentUserData$ = this.accountService.tokenData$.subscribe( data => {this.currentUserData = data});
  }

  onLike(event: any) {
    this.isActive = !this.isActive;
    this.isDisabled = true;

    if(this.currentUserData.isStudent){
      this.studentFollowProfessor();
    }

  }


  studentFollowProfessor() {
    if(this.isActive) {
      this.studentService.addProfessorToFavourites(this.userId).pipe(
        finalize(() => this.isDisabled = false)
      ).subscribe(
        () => {
          this.toastrService.success(this.translocoService.translate('msg-teacher-favourites-added-success'));
          const currentFavourites = this.dataStorageService.studentsFavouritesTeachers.getValue();

          currentFavourites.push({
            professorId: this.userId
          });

          this.dataStorageService.initializeStudentsFavourites(currentFavourites);
        },
        () => {
          this.toastrService.error(this.translocoService.translate('msg-something-went-wrong'));
          this.isActive = !this.isActive;
        }
      )
    } else {
      this.studentService.removeProfessorFromFavourites(this.userId).pipe(
        finalize(() => this.isDisabled = false)
      ).subscribe(
        () => {
          this.toastrService.success(this.translocoService.translate('msg-teacher-favourites-deleted-success'));

          const currentFavourites = this.dataStorageService.studentsFavouritesTeachers.getValue();

          this.dataStorageService.initializeStudentsFavourites(currentFavourites.filter((teacher:any) => teacher.professorId !==  this.userId));
        },
        () => {
          this.toastrService.error(this.translocoService.translate('msg-something-went-wrong'));
          this.isActive = !this.isActive;
        }
      )
    }
  }
}
