import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { MemberService } from 'src/app/core/services/member.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {

  croppedImage: any;
  scale = 1;

  imageChagnedEvent: any = '';
  imageTransform: ImageTransform = {};

  isUploading: boolean = false;

  professorPictureForm: UntypedFormGroup = new UntypedFormGroup({
    base64: new UntypedFormControl('', Validators.required)
  });


  @Output() onImageEvent: EventEmitter<{ resetPage: boolean }> = new EventEmitter();

  
  constructor(
    private _toastrService: ToastrService,
    public translocoService: TranslocoService,
    private _memberService: MemberService,
    private _spinnerService: SpinnerService

  ) { }

  ngOnInit(): void {
  }

  onImageUploadChange(event: any) {
    this.professorPictureForm.patchValue({
      base64: ''
    });

    this.imageChagnedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {

    if(event.height >= 300 && event.height >= 300) {
      this.croppedImage = event.base64;

      this.professorPictureForm.patchValue({
        base64: event.base64
      });

    } else {

      this.loadImageFailed();

    }

  }

  zoomOut() {
    this.scale -= .1;
    this.imageTransform = {
      ...this.imageTransform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.imageTransform = {
      ...this.imageTransform,
      scale: this.scale
    };
  }

  imageLoaded() {
  }

  cropperReady() {
  }

  loadImageFailed() {

    this._spinnerService.hideSpinner();
    this._toastrService.error(this.translocoService.translate('msg-picture-invalid-format'));

    this.professorPictureForm.patchValue({
      base64: ''
    });
  }

  uploadProfessorPicture() {

    
    this.isUploading = true;

    const imageBase64 = this.professorPictureForm.getRawValue()['base64'].split(',')[1];

    const imageBlob = this.dataURItoBlob(imageBase64);

    this._memberService.uploadProfessorProfilePicture(imageBlob).subscribe(
      () => {
        this.isUploading = false;
        this._toastrService.success(this.translocoService.translate('msg-picture-uploaded-successfully'))
        this.professorPictureForm.patchValue({
          base64: ''
        });
        this.onImageEvent.emit({ resetPage: true });
      },
      () => {
        this.isUploading = false;
        this._toastrService.error(this.translocoService.translate('msg-picture-upload-fail'));
        this.onImageEvent.emit({ resetPage: false });
      }

    );
  }

  dataURItoBlob(dataURI : string) {
    const byteString = window.atob(dataURI);

    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([int8Array], { type: 'image/png' });

    return blob;
  }

}
