import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account.service';

@Component({
  selector: 'app-student-register',
  templateUrl: './student-register.component.html',
  styleUrls: ['./student-register.component.scss']
})
export class StudentRegisterComponent implements OnInit {

  private openStudentRegisterModalSubscription!: Subscription;
  @Input() openStudentRegisterModalEvent!: Observable<void>;

  @ViewChild("studentRegisterModal") studentRegisterModal!: NgbModalRef;
  studentRegisterModalReference!: any;

  @ViewChild("confirmVerificationCodeModal") confirmVerificationCodeModal!: NgbModalRef;
  confirmVerificationCodeModalReference!: any;

  @ViewChild("resendVerificationCodeModal") resendVerificationCodeModal!: NgbModalRef;
  resendVerificationCodeModalReference!: any;

  @Output() openLoginModalFromRegister = new EventEmitter<{event: Event, parameters: any}>();

  constructor(
    private _modalService: NgbModal,
    private _accountService: AccountService,
    private _router: Router,
    private _toastr: ToastrService,
    public translocoService: TranslocoService
    ) { }

  isRegisterSpinnerVisible: boolean = false;
  isRegisterButtonDisabled: boolean = false;

  ngOnInit(): void {

    this.openStudentRegisterModalSubscription = this.openStudentRegisterModalEvent.subscribe(() => {

      this.openModal(this.studentRegisterModal);

    });

  }

  ngOnDestroy() {

    this.openStudentRegisterModalSubscription.unsubscribe();

  }

  openModal(content: any) {

    this.studentRegisterModalReference = this._modalService.open(

      content,

      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        modalDialogClass: "student-register-modal",
        backdropClass: "student-register-modal-backdrop"

      });

    this.studentRegisterModalReference.result.then((result: any) => {

    }, (reason: any) => {

      this.resetForm(this.studentRegisterForm);

      console.log("Student register modal closed");

    });

  }

  passwordMatchingValidatior: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');

    return password?.value === confirmPassword?.value ? null : { notmatched: true };
  };

  studentRegisterForm: UntypedFormGroup = new UntypedFormGroup({

    email: new UntypedFormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
    password: new UntypedFormControl('', [Validators.required, Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$^+=!*()@%&]).{8,}$")]),
    confirmPassword: new UntypedFormControl('', [Validators.required])

  }, { validators: this.passwordMatchingValidatior });

  verifyCodeForm: UntypedFormGroup = new UntypedFormGroup({

    verificationCode: new UntypedFormControl('', [Validators.required])

  }, { validators: this.passwordMatchingValidatior });

  onClickRegister() {
    this.isRegisterSpinnerVisible = true;
    this.isRegisterButtonDisabled = true;

    let formValues = this.studentRegisterForm.value;

    this._accountService.studentRegister(formValues)
    .pipe(finalize(() => {
      this.isRegisterSpinnerVisible = false;
      this.isRegisterButtonDisabled = false;
    }))
    .subscribe(
      (response) => {
        if(response === "email-alredy-exists") {
          this._toastr.error(this.translocoService.translate("register-email-exists"));
        } else if(response.tokenValue){
          this._accountService.logout(true);
          this._toastr.success(this.translocoService.translate('msg-registration-continue'))
          this.studentRegisterModalReference.close();

          const formData = this.studentRegisterForm.getRawValue();

          const loginParams = {
            email: formData.email,
            password: formData.password
          }

          this.onClickOpenLoginModalParams(loginParams);
        }
      }
    );
  }

  confirmVerificationCode() {

    const verificationCode = this.verifyCodeForm.getRawValue().verificationCode;

    const token = this._accountService.getCurrentUserToken();

    let confirmVerificationCodeDTO = {
      "verificationCode": verificationCode
    }

    this._accountService.verifyEmail(confirmVerificationCodeDTO, token).subscribe();

  }

  resendVerificationCode() {

    this.resendVerificationCodeModalReference.close();

    this.confirmVerificationCodeModalReference = this._modalService.open(

      this.confirmVerificationCodeModal,

      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        modalDialogClass: "confirm-verification-code-modal",
        backdropClass: "confirm-verification-d-modal-backdrop"
      });

    this.confirmVerificationCodeModalReference.result.then((result: any) => {

    }, (reason: any) => {

      this.resetForm(this.verifyCodeForm);

      console.log("Confirm verification code modal closed");

    });


  }

  resetForm(form: UntypedFormGroup) {

    form.reset();

    form.markAsPristine();

    form.markAsUntouched();

  }

  onClickOpenLoginModal() {

    this.studentRegisterModalReference.close();
    this.openLoginModalFromRegister.emit({ event: event!, parameters: { username: "", password: ""} });

  }

  onClickOpenLoginModalParams(loginParameters: any) {

    this.studentRegisterModalReference.close();

    this.openLoginModalFromRegister.emit({ event: event!, parameters: loginParameters });

  }

}
