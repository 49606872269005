import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { ContactOption } from 'src/app/core/models/member';
import { AccountService } from 'src/app/core/services/account.service';
import { FilterService } from 'src/app/core/services/filter.service';
import { MemberService } from 'src/app/core/services/member.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  contactModalRef: any;

  isContactFormEnabled: boolean = false;

  contactOptions: ContactOption[] | [];

  currentUser: any;

  contactForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.email]),
    category: new UntypedFormControl(1, [Validators.required]),
    message: new UntypedFormControl('', [Validators.required]),
  });





  logoUrl: string = "../../../assets/svgs/logo_white_2.svg";

  constructor(
    private _modalService: NgbModal,
    private router: Router,
    public accountService: AccountService,
    private filterService: FilterService,
    private memberService: MemberService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService
    ) { }

  ngOnInit(): void {

    this.fillContactFormOptions();

    this.accountService.currentUser$.subscribe(res => {

      this.currentUser = res;

      if(res) {
        this.populateContactForm();
      }

    });
  }

  enableContactForm() {
    this.isContactFormEnabled = !this.isContactFormEnabled;
  }

  closeContactForm() {
    this.contactForm.reset();
    this.contactModalRef.close();
  }

  fillContactFormOptions() {
    this.filterService.getAllContactFormOptions().subscribe({
      next: (response) => {
        this.contactOptions = response;
      }
    });
  }

  onSubmitContactForm() {
    this.memberService.postContactForm(this.contactForm.value).subscribe(
      res => {
        this.contactModalRef.close();
        this.populateContactForm();
        this.toastrService.success(this.translocoService.translate('contact-form-posted'));
      },
      err => this.toastrService.error(this.translocoService.translate('msg-something-went-wrong'))
      );
  }


  openContactModal(modal: any) {

    this.contactModalRef = this._modalService.open(
      modal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        scrollable: true
      }
    );
  }

  navigateTo(pageName: string) {
    const language = this.translocoService.getActiveLang();

    const path = `${language}/${pageName}`;
    
    this.router.navigate([path]);
  }

  ngOnDestroy() {
    if(this.contactModalRef) {
    this.contactModalRef.close();
    }
  }

  populateContactForm() {
    this.contactForm = new UntypedFormGroup({
      name: new UntypedFormControl(`${this.currentUser.firstName} ${this.currentUser.lastName}`, [Validators.required]),
      email: new UntypedFormControl(this.accountService.getUserEmail(), [Validators.email]),
      category: new UntypedFormControl(1, [Validators.required]),
      message: new UntypedFormControl('', [Validators.required]),
    });
  }




}
