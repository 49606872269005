import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { AccountService } from '../services/account.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private _router: Router,
    private _toastr: ToastrService,
    private _accountService: AccountService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    return next.handle(request).pipe(

      catchError(error => {

        if (error) {

          switch (error.status) {

            case 400:

              // this._toastr.error('Something went wrong.. Please try again later');

              console.log(error);

              break;

            case 401:

              this._accountService.logout(true);

              break;

            case 403:

              // this._toastr.error("User is authenticated but not authorized.");

              console.log(error);

              break

            case 404:

              // this._toastr.error('404 - Resource not found.');

              console.log(error);

              break;

            case 500:

              // this._toastr.error('500 - Internal server error, please come back later.');

              console.log(error);

              break;

            default:

              // this._toastr.error('Something went wrong.. Please try again later');

              console.log(error);

              break;

          }

        }

        return throwError(error);

      })
    )
  }
}
