<!-- Navbar (Solid background)-->
<!-- Remove "navbar-sticky" class to make navigation bar scrollable with the page.-->
<header class="header-wrapper header navbar navbar-expand-lg navbar-sticky {{navColor}}" data-scroll-header
    data-fixed-element>
    <div class="container px-0 px-xl-3 justify-content-end justify-content-md-between">

        <a class="navbar-brand flex-shrink-0 order-lg-1 me-auto ms-lg-0 pe-lg-2 me-lg-4 logo" href="index.html">
            <ng-lottie height="auto" width="200px" [options]="lottiePlayerOptions"></ng-lottie>
        </a>

        <div class="mlg-nav-list d-md-flex flex-sm-col flex-md-row py-7 py-md-0 align-items-center order-lg-3 ms-lg-auto">

            <li *ngIf="(accountService.currentUser$ | async) === null && showRegisterButton" class="nav-item pt-3 pt-md-0">
                <app-student-register [openStudentRegisterModalEvent]="openStudentRegisterModalSubject.asObservable()"
                    (openLoginModalFromRegister)="onOpenLoginModalEvent($event)"></app-student-register>
            </li>

            <li *ngIf="(accountService.currentUser$ | async) === null" class="nav-item pt-3 pt-md-0"  style="margin-right: 1rem">
                <app-professor-register [openProfessorRegisterModalEvent]="openProfessorRegisterModalSubject.asObservable()"
                    (openLoginModalFromRegister)="onOpenLoginModalEvent($event)"></app-professor-register>
            </li>

            <li *ngIf="(accountService.currentUser$ | async) === null" class="nav-item login-border pt-3 pt-md-0">
                <app-login [openLoginModalEvent]="openLoginModalSubject.asObservable()"
                    (openStudentRegisterModalFromLogin)="onOpenStudentRegisterModal()"></app-login>
            </li>



            <li *ngIf="(accountService.currentUser$ | async) as user" class="nav-item ">

                <ng-container *ngIf="(accountService.tokenData$ | async) as roleData">

                    <ng-container
                        [ngTemplateOutlet]="roleData.isProfessor ? professorNav : studentNav"
                        [ngTemplateOutletContext]="{user:user}">
                    </ng-container>

                </ng-container>

            </li>

        </div>

        <div class="offcanvas offcanvas-collapse order-lg-2" id="primaryMenu">
            <div class="offcanvas-header navbar-shadow">
                <h5 class="mt-1 mb-0">{{ 'label-menu' | transloco }}</h5>
                <button class="btn-close lead" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <!-- Menu-->
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="/">{{ 'label-home' | transloco }}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <app-language-selector></app-language-selector>

</header>

<ng-template #studentNav let-user="user">

    <div class="navbar-tool dropdown">

        <a class="navbar-tool-label dropdown-toggle" href="javascript:;" id="user-profile-toggle"
            data-bs-toggle="dropdown" data-bs-auto-close="true"
            aria-expanded="false"><small>{{ 'label-hello' | transloco }}</small>{{user.firstName | titlecase}}</a>

        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="user-profile-toggle"
            style="width: 15rem;">

            <li>
                <a class="dropdown-item d-flex align-items-center"
                    routerLink="/edit-user-profile/profile-info">
                    <i class="ai-user fs-base opacity-60 me-2"></i>
                    {{ 'label-profile' | transloco }}
                </a>
            </li>

            <li class="dropdown-divider"></li>

            <li>
                <a class="dropdown-item d-flex align-items-center"
                [routerLink]="['/messages']" [ngClass]="(accountService.numberOfMessages$ | async) == 0? '' : 'new'">
                    <i class="ai-message-square fs-base opacity-60 me-2"></i>
                    {{ 'label-messages' | transloco }}
                    <b class="mx-2">{{ accountService.numberOfMessages$ | async}}</b>
                </a>
            </li>

            <li class="dropdown-divider"></li>

            <li>
                <a class="dropdown-item d-flex align-items-center"
                    routerLink="/section/rate-teachers"
                    [ngClass]="(accountService.numberOfTeachersToRate$ | async) == 0? '' : 'new'">
                    <i class="ai-star fs-base opacity-60 me-2"></i>
                    {{ 'rate-teachers' | transloco }}
                    <b class="mx-2">{{ accountService.numberOfTeachersToRate$ | async}}</b>
                </a>
            </li>

            <li class="dropdown-divider"></li>

            <li>
                <a (click)="accountService.logout()" class="dropdown-item d-flex align-items-center"
                    href="javascript:;"><i class="ai-log-out fs-base opacity-60 me-2"></i>
                    {{ 'label-logout' | transloco }}
                </a>
            </li>

            
        </ul>

    </div>

</ng-template>

<ng-template #professorNav let-user="user">

  <div class="d-flex">
    <div class="navbar-tool dropdown">

        <a class="navbar-tool-icon-box" href="javascript:;">
            <img class="navbar-tool-icon-box-img" src="{{user.imageUrl}}" alt="image">
        </a>

        <a class="navbar-tool-label dropdown-toggle" href="javascript:;" id="user-profile-toggle"
            data-bs-toggle="dropdown" data-bs-auto-close="true"
            aria-expanded="false"><small>{{ 'label-hello' | transloco }}</small>{{user.firstName | titlecase}}</a>

        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="user-profile-toggle"
            style="width: 15rem;">

            <li>
                <a class="dropdown-item d-flex align-items-center"
                    [routerLink]="['/user-profile', user.professorId]">
                    <i class="ai-user fs-base opacity-60 me-2"></i>
                    {{ 'label-profile' | transloco }}
                </a>
            </li>

            <li class="dropdown-divider"></li>

            <li>
                <a class="dropdown-item d-flex align-items-center"
                [routerLink]="['/messages']" [ngClass]="(accountService.numberOfMessages$ | async) == 0? '' : 'new'">
                    <i class="ai-message-square fs-base opacity-60 me-2"></i>
                    {{ 'label-messages' | transloco }}
                    <b class="mx-2">{{ accountService.numberOfMessages$ | async}}</b>
                </a>
            </li>

            <li class="dropdown-divider"></li>

            <li>
                <a class="dropdown-item d-flex align-items-center"
                    routerLink="/edit-user-profile/profile-info">
                    <i class="ai-settings fs-base opacity-60 me-2"></i>
                    {{ 'label-settings' | transloco }}
                </a>
            </li>

            <li class="dropdown-divider"></li>

            <li>
                <a (click)="accountService.logout()" class="dropdown-item d-flex align-items-center"
                    href="javascript:;"><i class="ai-log-out fs-base opacity-60 me-2"></i>
                    {{ 'label-logout' | transloco }}
                </a>
            </li>

        </ul>



    </div>

    <div  *ngIf="!user.isPro" class="px-3">
      <app-become-pro-btn></app-become-pro-btn>
    </div>
  </div>

</ng-template>
