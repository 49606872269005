import { Filter } from './filter';

export class MemberParams {
    currentPage: number | null = 1;
    itemsPerPage: number | null = 3;
    sortBy: number = 0;
    orderBy: string = 'asc';
    filters: Filter[] = [];
    fullName: string;
    filterFollowingProfessors: boolean;

    constructor() {
    }
}