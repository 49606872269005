import { provideCloudflareLoader } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { ProfessorToBeRated } from 'src/app/core/models/models';
import { MemberService } from 'src/app/core/services/member.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {

  @Input() professor: ProfessorToBeRated;
  @Output() professorRated = new EventEmitter();

  fullName: string;
  isReadonly: boolean = false;

  constructor(
    private _memberService: MemberService,
    private _toastrService: ToastrService,
    private _translocoService: TranslocoService
  ) { }

  ngOnInit(): void {
    this.fullName = `${this.professor.firstName} ${this.professor.lastName}`;
  }

  

  rateProfessor(ratingValue: any) {
    
    this._memberService.rateTeacher({
      professorId: this.professor.professorId,
      ratingValue
    }).subscribe(
      res => { 
        this._toastrService.success(this._translocoService.translate('rate-sucessfully-rated'));
        this.professorRated.emit({ professorId: this.professor.professorId});
      },
      err => this._toastrService.error(this._translocoService.translate('msg-something-went-wrong'))
    );
    
  }


}
