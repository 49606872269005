import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {

  public followingCollection = new BehaviorSubject<any>(null);
  public globalDecimal = new BehaviorSubject<number>(2);
  public globalCurrency = new BehaviorSubject<string>("$");
  public studentsFavouritesTeachers = new BehaviorSubject<any>(null);

  constructor(
  ) { }

  initializeStudentsFavourites(teacherList: any) {
    this.studentsFavouritesTeachers.next(teacherList);
  }
}
