import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-become-pro-btn',
  templateUrl: './become-pro-btn.component.html',
  styleUrls: ['./become-pro-btn.component.scss']
})
export class BecomeProBtnComponent implements OnInit, OnDestroy {

  popModalRef: any;

  @Input() isBasicEnabled: boolean = false;
  @Input() btnName: string = "Become a Pro Member";
  @Output() onBasicClick: EventEmitter<any> = new EventEmitter();

  constructor(private _modalService: NgbModal,) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.popModalRef.close();
  }

  onBasicOption() {
    this.onBasicClick.emit();
  }


  openPricesModal(modal: any) {

    this.popModalRef = this._modalService.open(
      modal,
      {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        scrollable: true
      });
  }

  ngOnDestroy() {
    if(this.popModalRef) {
      this.closeModal();
    }
  }

}
