import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from 'src/assets/app.config';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(
    private apiService : ApiService,
    private _httpClient: HttpClient,
    private _appConfig: AppConfig
  ) { }

  addProfessorToFavourites(professorId : string) {
    const payload = {
      professorId: professorId
    }

    return this.apiService.post('followProfessor', payload);
  }

  removeProfessorFromFavourites(professorId : string) {

    const payload = {
      professorId: professorId
    }

    return this.apiService.delete('unfollowProfessor', payload);
  }

  getListOfFavouriteProfessors() {

    let httpParams = new HttpParams();

    const currentPage = 1;
    const numberPerPage = 5;
    const sortBy= 0;

    httpParams = httpParams.append("Pagination.CurrentPageNumber", currentPage.toString());
    httpParams = httpParams.append("Pagination.NumberPerPage", numberPerPage.toString());
    httpParams = httpParams.append("Pagination.SortBy", sortBy.toString());

    this._httpClient.get(`${this._appConfig.apiUrl}Student/ProfessorFollowers`,{

      observe: 'response',
      // params: httpParams

    }).subscribe(
      data => console.log('api', data),
      error => console.log('error api', error)
    )

    // this.apiService.get("studentFavouriteCollection", payload).subscribe(
    //   data => console.log('api', data),
    //   error => console.log('error api', error)
    // )
  }

}
