<div class="d-flex mx-3 my-3 rounded-3 justify-content-around py-5 px-5 align-items-center" [ngClass]="professor.isPro? 'bg-pro':'bg-basic'">

    <img class="rounded-circle border border-dark professor-image" [attr.src]="professor.imageUrl" alt="image">

    <div class=" d-flex flex-column mx-3">

        <span class="teacher-name">{{fullName}}</span>

        <ngb-rating class="mt-3 rounded rating bg-light custom-rating" [max]="5"
            [readonly]="isReadonly" [rate]="professor.averageRating" (rateChange)="rateProfessor($event)">
        </ngb-rating>
        
    </div>
    
</div>