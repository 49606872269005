import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { MemberService } from './member.service';
import { ApiService } from './api.service';
import { AppConfig } from 'src/assets/app.config';
import { DataStorageService } from './data-storage/data-storage.service';
import { TranslocoService } from '@ngneat/transloco';
import { SpinnerService } from './spinner.service';

@Injectable({
    providedIn: 'root',
})
export class AccountService {

    private baseURL: string = "";

    private currentUserSource = new ReplaySubject<any>(1);
    private tokenDataSoruce = new ReplaySubject<any>(1);
    public currentUserStage = new BehaviorSubject<any>(null);
    private numberOfMessages = new BehaviorSubject<any>(null);
    private numberOfTeachersToRate = new BehaviorSubject<any>(null);

    public currentUser$ = this.currentUserSource.asObservable();
    public tokenData$ = this.tokenDataSoruce.asObservable();
    public numberOfMessages$ = this.numberOfMessages.asObservable();
    public numberOfTeachersToRate$ = this.numberOfTeachersToRate.asObservable();
    public currentUserDecodedToken: any;

    private currentUserToken: string = "";


    constructor(
        private _router: Router,
        private _httpClient: HttpClient,
        private _toastr: ToastrService,
        private _memberService: MemberService,
        private _apiService: ApiService,
        private _appConfig: AppConfig,
        private _dataStorageService: DataStorageService,
        private _spinnerService: SpinnerService,
        public translocoService: TranslocoService,
    ) {
        this.baseURL = _appConfig.apiUrl;
     }

    login(formData: any): Observable<any> {

        return this._httpClient.post<any>(this.baseURL + `Account/login`, formData, { observe: 'response' }).pipe(

            map((response: any) => {
                this.currentUserToken = response.body.tokenValue;
                return this.getDecodedAccessToken(this.currentUserToken);;
            }),

            switchMap(decodedTokenValues => {

                const tokenHasKeys = Object.values(decodedTokenValues).some(x => x !== null && x !== '');

                if (tokenHasKeys) {

                    let userRole = decodedTokenValues.role;
                    const userId = decodedTokenValues.sid;

                    this.setUserRoleData(decodedTokenValues);

                    if(typeof userRole == "object") {
                        userRole = userRole[0];
                    }


                    this.setUserRegistrationStage(decodedTokenValues.stage);

                    if (userRole.toLowerCase() === "professor" || userRole.toLowerCase() === "proprofessor") {

                        return this._memberService.getProfessorMember(userId).pipe(

                            map(result => {

                                let currentUser = result;

                                this.setBrowserToken(this.currentUserToken);

                                this.setCurrentUser(currentUser);

                                this._dataStorageService.initializeStudentsFavourites(null);

                                if(decodedTokenValues.stage !== "Completed") {
                                    this._toastr.success(this.translocoService.translate('registration-not-completed'));
                                } else {
                                    this._toastr.success(`${this.translocoService.translate('msg-welcome-teacher')} ${currentUser.firstName ? currentUser.firstName : ''}, ${this.translocoService.translate('msg-welcome-back')}`);
                                }

                                this.updateNewMessages();
                                this.updateTeachersToRate();

                                return true;

                            })

                        );

                    }

                    else if (userRole.toLowerCase() === "student") {

                        return this._memberService.getStudentMember(userId).pipe(

                            map(result => {

                                let currentUser = result;

                                this.setBrowserToken(this.currentUserToken);

                                this.setCurrentUser(currentUser);

                                if(decodedTokenValues.stage !== "Completed") {
                                    this._toastr.success(this.translocoService.translate('registration-not-completed'));
                                } else {
                                    this._toastr.success(`${this.translocoService.translate('msg-welcome-student')} ${currentUser.firstName ? currentUser.firstName : ''}, ${this.translocoService.translate('msg-welcome-back')}`);
                                    this._memberService.getStudentsFavouriteTeachers().subscribe(teachers => {
                                        this._dataStorageService.initializeStudentsFavourites(teachers.result);
                                    });
                                }

                                this.updateNewMessages();
                                this.updateTeachersToRate();

                                return true;

                            })

                        );

                    }

                    else {
                        
                        this._toastr.error(this.translocoService.translate('msg-user-not-supported'));

                        return of(false);

                    }

                }

                // if user doesn't exist
                else {

                    return of(false);

                }

            }),

            catchError(error => {

                if(error.status == 406) {
                    this._toastr.error(this.translocoService.translate('msg-user-banned'));
                }

                return of(false);
            })
        )
    }

    studentRegister(formData: any) {

        const payload = {
            "email" : formData.email,
            "password" : formData.password
        }

        return this._apiService.post("studentRegister", payload).pipe(
            catchError(error => {
                if(error.status === 409) {
                    this._toastr.success(this.translocoService.translate('msg-registration-fail-exists'))
                    return of("email-alredy-exists");
                } else if(error.status === 400){
                    this._toastr.error(this.translocoService.translate('msg-registration-fail-invalid'))
                    return of(error.errors)
                } else {
                    this._toastr.error(this.translocoService.translate('msg-something-went-wrong'))
                    return of("server-error")
                }
            })
        );
    }

    professorRegister(formData: any) {

        const payload = {
            "email" : formData.email,
            "password" : formData.password
        }

        return this._apiService.post("professorRegister", payload).pipe(
            map(response => {
                this.currentUserToken = response.tokenValue;
                this.setBrowserToken(response.tokenValue);
                const decodedToken = this.getDecodedAccessToken(this.currentUserToken);
                this.setUserRegistrationStage(decodedToken.stage);
                return decodedToken;
            }),
            catchError(error => {
                if(error.status === 409) {
                    this._toastr.success(this.translocoService.translate('msg-registration-fail-exists'))
                    return of("email-alredy-exists");
                } else if(error.status === 400){
                    this._toastr.error(this.translocoService.translate('msg-registration-fail-invalid'))
                    return of(error.errors)
                } else {
                    this._toastr.error(this.translocoService.translate('msg-something-went-wrong'))
                    return of("server-error")
                }
            })
        );
    }

    sendResetPasswordToken(formData: any){
        const payload = {
            email: formData.email
        }

        return this._apiService.post("resetPasswordToken",payload);
    }

    setUserRegistrationStage(stage: string) {
        this.currentUserStage.next(stage);
        this.setUserStageLocalStorage(stage);
    }

    getCurrentUserToken() {
        return this.currentUserToken;
    }

    verifyEmail(formData: any, token:string) {

        const payload = formData;

        const headers = new HttpHeaders({
            'Authorization':`Bearer ${token}`
        });

        return this._httpClient.post<any>(this._appConfig.apiUrl + `Student/verify-email`, formData, { observe: 'response', headers: { 'Authorization' : `Bearer ${token}`} }).pipe(

            map(response => {

                console.log(response);

                return response;

            }), catchError(error => {

                if (error.error instanceof ErrorEvent) {

                    console.log("Error!");

                    this._toastr.error(this.translocoService.translate('msg-verify-email-fail'));

                } else {

                    console.log("Error!");

                    this._toastr.error(this.translocoService.translate('msg-verify-email-fail'));

                }

                return of(false);
            })
        );
    }

    verifyEmailStudent(formData: any) {
        const payload = formData;

        return this._apiService.post("studentVerifyEmail", payload);
    }

    verifyEmailProferssor(formData: any) {
        const payload = formData;

        return this._apiService.post("professorVerifyEmail", payload);
    }

    resendVerificationCodeToEmailProfessor() {
        return this._apiService.post("resendVerificationCodeProfessor", {});
    }

    resendVerificationCodeToEmailStudent() {
        return this._apiService.post("resendVerificationCodeStudent", {});
    }

    resetUserPassword(payload: any) {
        return this._apiService.post("resetUserPassword", payload);
    }

    // triggered on page refresh
    // token is checked if it's null in app component
    getUserFromToken(decodedTokenValues: any) {

        let userRole = decodedTokenValues.role;
        const userId = decodedTokenValues.sid;

        this.setUserRoleData(decodedTokenValues);

        if(typeof userRole == "object") {
            userRole = userRole[0];
        }

        this.updateNewMessages();
        this.updateTeachersToRate();

        if (userRole.toLowerCase() === "professor" || userRole.toLowerCase() === "proprofessor") {

            return this._memberService.getProfessorMember(userId).pipe(

                map(result => {

                    let currentUser = result;

                    this.setCurrentUser(currentUser);

                    return true;

                })

            ).subscribe();

        }

        else if (userRole.toLowerCase() === "student") {

            return this._memberService.getStudentMember(userId).pipe(

                map(result => {

                    let currentUser = result;

                    if(!this._dataStorageService.studentsFavouritesTeachers.getValue()) {
                        this._memberService.getStudentsFavouriteTeachers().subscribe(teachers => {
                            this._dataStorageService.initializeStudentsFavourites(teachers.result);
                        });
                    }

                    this.setCurrentUser(currentUser);

                    return true;

                })

            ).subscribe();

        }

        else {

            this.logout();

            return false;

        }

    }

    setUserRoleData(decodedToken: any) {

        let roles : Array<string> = [];

        if(Array.isArray(decodedToken.role)){
            roles = [...decodedToken.role]
        } else {
            roles.push(decodedToken.role);
        }

        const isStudent = roles.includes('Student');
        const isProfessor = roles.includes('Professor');
        const isPro = isProfessor ? roles.includes('ProProfessor') : false;
        const isAdmin = roles.includes('SuperAdmin');

        const roleData = {
            id: decodedToken.sid,
            isStudent : isStudent,
            isProfessor : isProfessor,
            isPro : isPro,
            isAdmin : isAdmin
        }

        this.tokenDataSoruce.next(roleData);

    }

    setCurrentUser(user: any) {

        this.currentUserSource.next(user);

    }

    updateCurrentUser(isProfessor: boolean, id: string) {
        this._spinnerService.showSpinner();
        if(!isProfessor) {
          this._memberService.getStudentMember(id).pipe(
            map((result : any) => {
              this.setCurrentUser(result);
              this._spinnerService.hideSpinner();
              return true;
            })
          ).subscribe();
        } else {
          this._memberService.getProfessorMember(id).pipe(
            map((result : any) => {
              this.setCurrentUser(result);
              this._spinnerService.hideSpinner();
              return true;
            })
          ).subscribe();
        }
      }

    setBrowserToken(token: any) {

        localStorage.setItem('user-token', JSON.stringify(token));

    }

    setUserStageLocalStorage(stage: string) {
        localStorage.setItem('stage', stage);
    }

    getUserStageLocalStorage() : string | null {
        const stage =  localStorage.getItem('stage');

        return stage;
    }

    getDecodedAccessToken(token?: string): any {
        try {
            this.currentUserDecodedToken = jwt_decode(token? token : this.currentUserToken);
            return this.currentUserDecodedToken;
        }
        catch (Error) {
            return null;
        }
    }


    loggedIn() {
        return JSON.parse(localStorage.getItem('user-token')!);

    }

    logout(disableMessage?: boolean) {

        localStorage.removeItem('user-token');
        localStorage.removeItem('stage');

        this.currentUserSource.next(null);
        this.tokenDataSoruce.next(null);
        this.currentUserStage.next(null);

        this._router.navigate(['/']);

        if(disableMessage)
            return;
        this._toastr.success(this.translocoService.translate('msg-logout-success'));

    }

    checkIfTokenIsValid() {
        const token = localStorage.getItem('user-token');

        if(token) {
            const decodedToken = this.getDecodedAccessToken(token);
            return decodedToken.exp > (Date.now() / 1000) ? true : false;
        }

        return false;
    }

    updateNewMessages() {
        this._memberService.getAllConversations().subscribe(
            data => {
                let counter = 0;

                data.chatCards.map((card:any) => {
                    if(card.isUnread) {
                        counter++
                    }
                });

                this.numberOfMessages.next(counter);
            }
        );
    }

    updateTeachersToRate() {
        this._memberService.getTeachersToRate().subscribe(
            data => {
                this.numberOfTeachersToRate.next(data.count);
            }
        );
    }

    getSelectedLanguage() {
      let language = localStorage.getItem('language');

      if(!language) {
        this.setSelectedLanguage('en');
      }

      return localStorage.getItem('language') ?? "en";
    }

    setSelectedLanguage(language: string) {
      localStorage.setItem('language', language);
    }

    changeTranslocoLanguage(language: string) {
      this.translocoService.setActiveLang(language);
      this.setSelectedLanguage(language);
    }

    getUserEmail() {
        return this.currentUserDecodedToken.email;
    }
}
