import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConversationIds } from 'src/app/content/pages/messages/messages.models';
import { MemberService } from 'src/app/core/services/member.service';

@Component({
  selector: 'app-send-message-btn',
  templateUrl: './send-message-btn.component.html',
  styleUrls: ['./send-message-btn.component.scss']
})
export class SendMessageBtnComponent implements OnInit {

  @Input() professorId: string;
  @Input() studentId: string;
  @Input() buttonText: string;
  @Output() onChatCreated: EventEmitter<any> = new EventEmitter();

  constructor(
    private memberService: MemberService
  ) { }

  ngOnInit(): void {

  }

  createNewChatWithParticipants() {

    const payload: ConversationIds = {
      studentId: this.studentId,
      professorId: this.professorId
    }

    this.memberService.checkIfChatExists(payload).subscribe(
      (res) => {
        this.onChatCreated.emit(res);
      },
      (err) => {
        this.onChatCreated.emit(err.status)
      }
    )
  }
}