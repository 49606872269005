import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { response } from 'express';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { URL_LIST } from 'src/app/shared/constants/url-list';
import { AppConfig } from 'src/assets/app.config';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private appConfig: AppConfig
  ) {}

  private ENV_URL: string = this.appConfig.apiUrl;
  private urlList: any = URL_LIST;

  get(type: string, query?: any): Observable<any> {
    return this.http.get(this.getUrl(type, query)).pipe(
      map((response) => this.handleResponse(response)),
      catchError((error) => this.handleError(error))
    );
  }

  post(type: string, payload: any, opitons?: any): Observable<any> {
    return this.http.post(this.getUrl(type), payload, opitons).pipe(
      map((response) => this.handleResponse(response)),
      catchError((error) => this.handleError(error))
    );
  }

  put(type: string, payload: any): Observable<any> {
    return this.http.put(this.getUrl(type), payload).pipe(
      map((response) => this.handleResponse(response)),
      catchError((error) => this.handleError(error))
    );
  }

  patch(type: string, payload: any): Observable<any> {
    return this.http.patch(this.getUrl(type), payload).pipe(
      map((response) => this.handleResponse(response)),
      catchError((error) => this.handleError(error))
    );
  }

  delete(type: string, payload: any): Observable<any> {
    return this.http.request('delete', this.getUrl(type), { body: payload }).pipe(
      map((response) => this.handleResponse(response)),
      catchError((error) => this.handleError(error))
    )
  }

  getUrl(type: string, query?: any): string {
    let url = this.ENV_URL + this.urlList[type];
    if (typeof query === 'string') {
      url += query;
    } else if (query) {
      url += this.getQueryString(query);
    }
    return url;
  }

  getQueryString(payload: any) {
    let query = Object.keys(payload)
      .map(function (k) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(payload[k]);
      })
      .join('&');
    query = `?${query}`;
    return query;
  }

  handleResponse(response: any) {
    if (response && response.error) this.handleCustomServerError(response);
    else return response;
  }

  handleError(error: any) {
    if (error.status === 401) {
      // TODO: Configure this option
      this.router.navigate(['/login']);
      return [];
    } else {
      let errMsg = error.message || 'Server Error';
      return throwError(error);
    }
  }

  handleCustomServerError(response: any) {
    throw new Error(response.error);
  }
}
