import { Pipe, PipeTransform } from '@angular/core';
import { DataStorageService } from '../services/data-storage/data-storage.service';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {

  constructor(
    private dataStorageService: DataStorageService
  ){

  }

  transform(value: number, decimalPoints?: number, currencySign? : string): string {

    const decimals = decimalPoints? decimalPoints : this.dataStorageService.globalDecimal.value;
    const currency = currencySign? currencySign : this.dataStorageService.globalCurrency.value;

    let formattedValue = currency + " " + value.toFixed(decimals).toString();


    return formattedValue;
  }

}
